import { VFC } from 'react';
import { CircularProgress } from '@material-ui/core';

export const AchievementUploadLoading: VFC = () => (
  <div
    className="pr-3 pt-3"
    style={{
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={55} style={{ color: '#007AFF' }} />
    <div
      style={{
        paddingTop: 32,
        paddingBottom: 16,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.6)',
      }}
    >
      実績を登録中
    </div>
  </div>
);
