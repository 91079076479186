import { useEffect } from 'react';
import _ from 'lodash';

import RouteModal from './modals/RoutePlanModal';
import LocationModal from './modals/LocationModal';
import LocationAdditionModal from './modals/LocationAdditionModal';
import { AchievementUploadModal } from './modals/AchievementUploadModal';
import { AchievementDownloadModal } from './modals/AchievementDownloadModal';
import { RouteMasterDetailModal } from './modals/RouteMasterDetailModal';
import { BaseMasterDetailModal } from './modals/BaseMasterDetailModal';

interface Props {
  visible: boolean;
  type: string;
  content: any;
  onClickClose: () => void;
}

const modalTypes: any = {
  route: RouteModal,
  location: LocationModal,
  locationAddition: LocationAdditionModal,
  achievementUpload: AchievementUploadModal,
  achievementDownload: AchievementDownloadModal,
  routeMasterDetail: RouteMasterDetailModal,
  baseMasterDetail: BaseMasterDetailModal,
};

const ModalBase = ({ visible, type, content, onClickClose }: Props) => {
  useEffect(() => {
    const { document } = global;
    const dialogEl: any = document.querySelector('dialog');
    if (dialogEl) dialogEl.scrollTop = 0;
  });

  const ModalContent = modalTypes[type];

  return (
    <>
      <dialog
        open={visible}
        style={{
          position: 'fixed',
          top: 40,
          zIndex: 4,
          // TODO: 呼び出し側でwidth幅を変更できるようにしたい
          width: 800,
          maxHeight: global.innerHeight - 40 * 2,
          overflowX: 'hidden',
          overflowY: 'auto',
          border: 'none',
          padding: 32,
        }}
      >
        {Boolean(type) ? (
          _.isFunction(ModalContent) ? (
            ModalContent({ content, onClickClose })
          ) : (
            <ModalContent content={content} onClickClose={onClickClose} />
          )
        ) : null}
      </dialog>

      <div
        style={{
          visibility: visible ? 'visible' : 'hidden',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0 ,0.4)',
          zIndex: 3,
        }}
        onClick={onClickClose}
      />
    </>
  );
};

export default ModalBase;
