import React, { VFC } from 'react';
import moment from 'moment';

type Props = {
  date: Date;
};

export const DateRangePickerCaption: VFC<Props> = ({ date }) => (
  <div
    className={'DayPicker-Caption'}
    style={{ textAlign: 'center', fontWeight: 'bold' }}
  >
    {moment(date).format('YYYY年M月')}
  </div>
);
