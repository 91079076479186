import { VFC } from 'react';

type LoadingRateProps = {
  plan: number;
  achievement?: number;
};

export const LoadingRate: VFC<LoadingRateProps> = ({ plan, achievement }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span className="mr-1" style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.38)' }}>
        計画
      </span>
      <span className="mr-2" style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.38)' }}>
        {plan ?? '-'}
      </span>
      <span className="mr-1" style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.6)' }}>
        実績
      </span>
      <span style={{ fontSize: 24 }}>{achievement ?? '-'}</span>
    </div>
  );
};
