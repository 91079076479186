import React, { memo, useCallback, useRef, VFC } from 'react';
import { useDispatch } from 'react-redux';

import {
  Clear,
  CloudDownload,
  CloudUpload,
  Search,
  Today,
} from '@material-ui/icons';
import {
  MuiThemeProvider,
  createMuiTheme,
  IconButton,
  InputAdornment,
  Select,
  TextField,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment, locale } from 'moment';
import 'moment/locale/ja';

import { openModal } from '../../reducers/core';
import { Locations, SearchConditions } from '../../types/BaseView/types';

import { ToggleButton } from './ToggleButton';

interface HeaderProps {
  conditions: SearchConditions;
  locations: Locations;
  setLocationName: (name: string) => void;
  setDate: (date: Date) => void;
  setShipperName: (name: string) => void;
  setIsOnlyNotAchieved: (isAchievement: boolean) => void;
  setPressedTime: (date: Date | undefined) => void;
  downloadCSV: () => void;
}

const ja = locale('ja');

export const Header: VFC<HeaderProps> = memo(
  ({
    locations,
    setLocationName,
    conditions,
    setDate,
    setShipperName,
    setIsOnlyNotAchieved,
    setPressedTime,
    downloadCSV,
  }) => {
    const dispatch = useDispatch();

    const changeLocationName = useCallback(
      (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
      ) => {
        setLocationName(String(event.target.value));
      },
      [setLocationName]
    );

    const changeDay = useCallback(
      (date: Moment | null) => {
        if (!date) {
          return;
        }
        const selectedDate = date.toDate();
        setDate(selectedDate);
      },
      [setDate]
    );

    const changeShipperName = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setShipperName(event.target.value);
      },
      [setShipperName]
    );

    const clearButtonRef = useRef<HTMLInputElement | null>(null);
    const clearShipperName = useCallback(() => {
      setShipperName('');
      clearButtonRef.current?.blur();
    }, [setShipperName]);

    const toggleAchieved = useCallback(
      (isActive: boolean) => {
        setIsOnlyNotAchieved(isActive);
      },
      [setIsOnlyNotAchieved]
    );

    const toggleRecentSchedule = useCallback(
      (isSelected: boolean) => {
        isSelected ? setPressedTime(new Date()) : setPressedTime(undefined);
      },
      [setPressedTime]
    );

    const openAchievementUploadModal = () => {
      dispatch({
        type: openModal.type,
        payload: { type: 'achievementUpload' },
      });
    };

    const theme = createMuiTheme({
      overrides: {
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: '#FFF',
            },
          },
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <div
          style={{
            padding: 14,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Noto Sans JP',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Select
              native
              className="mr-2"
              style={{
                width: 160,
                height: 36,
                fontSize: 12,
                color: 'rgba(0, 0, 0, 0.6)',
              }}
              variant="outlined"
              value={conditions.locationName}
              onChange={changeLocationName}
            >
              {locations?.map(({ name }, idx) => (
                <option key={idx} value={name}>
                  {name}
                </option>
              ))}
            </Select>
            <TextField
              style={{ width: 200 }}
              size="small"
              className="mr-2"
              value={conditions.shipperName}
              variant="outlined"
              placeholder="荷主検索する"
              onChange={changeShipperName}
              InputProps={{
                style: {
                  fontSize: 12,
                  height: 36,
                  color: 'rgba(0, 0, 0, 0.6)',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={clearShipperName}
                      size="small"
                      innerRef={clearButtonRef}
                    >
                      <Clear
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MuiPickersUtilsProvider utils={MomentUtils} locale={ja}>
              <DatePicker
                className="mr-2"
                style={{ width: 150 }}
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="YYYY年MM月DD日"
                value={conditions.date}
                onChange={changeDay}
                InputProps={{
                  style: {
                    fontSize: 12,
                    height: 36,
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Today
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }}
                      />
                    </InputAdornment>
                  ),
                }}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <ToggleButton label={'未入力を表示'} onChange={toggleAchieved} />
            <ToggleButton
              label={'直近の予定へ移動'}
              onChange={toggleRecentSchedule}
              value={!!conditions.pressedTime}
            />
          </div>
          <div>
            <button className="mr-3" onClick={downloadCSV}>
              <CloudDownload
                className="mr-2"
                style={{ color: 'rgba(0, 0, 0, 0.38)' }}
              />
              実績取得
            </button>
            <button onClick={openAchievementUploadModal}>
              <CloudUpload
                className="mr-2"
                style={{ color: 'rgba(0, 0, 0, 0.38)' }}
              />
              実績登録
            </button>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
);
