import { useEffect, useState } from 'react';

import { BaseMaster } from '../types/BaseMasterList/types';
import { BaseMasterService } from '../services/BaseMasterService';
import { HookResultType } from '../types/hooks';

export const useBaseMasters = (): HookResultType<BaseMaster[]> => {
  const [result, setResult] = useState<HookResultType<BaseMaster[]>>({
    loading: true,
  });

  useEffect(() => {
    BaseMasterService.findAll()
      .then((res) =>
        setResult({
          loading: false,
          data: res,
          error: undefined,
        })
      )
      .catch((error) =>
        setResult({
          loading: false,
          data: undefined,
          error: error,
        })
      );
  }, []);

  return result;
};
