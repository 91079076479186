interface Props {
  routePlan: any
  style?: any
  onClick: () => void
}

const RoutePlanSimple = ({ routePlan, style, onClick }: Props) => {
  const routeMaster: any = routePlan.route_master_ref || { name: '' }

  return (
    <div
      className="rounded fs-14 clickable text-truncate text-center"
      style={{ width: 104, height: 26, border: '1px solid #9A9895', ...style }}
      onClick={onClick}
    >
      {routeMaster.name}
    </div>
  )
}

export default RoutePlanSimple
