// general
export const SECONDS_OF_MINUTE = 60;
export enum RouteType {
  KYOTENKAN,
  KANSEN,
}

// 日付の開始時刻
export const START_TIME = '04:00';
// 日付の終了時刻
export const END_TIME = '03:59';

// UI
export const WIDTH_OF_1HOUR = 104;
export const LEFT_FIXED_AREA_WIDTH = 120;
export const LOCATION_CARD_HEIGHT = 80;
export const SIMPLE_LOCATION_CARD_HEIGHT = 26;
export const HOURS_OF_DAY = 24;
export const HEADER_HEIGHT = 170;
// 左端の時刻、最小時刻 - 余白分の1時間
export const FAR_LEFT_TIME = 4 - 1;
// 右端の時刻、最大時刻 + 余白分の1時間
export const FAR_RIGHT_TIME = 4 + HOURS_OF_DAY + 2;
// 表示件数
export const ROUTE_PLAN_DISPLAY_ITEMS = 25;

export const FIRESTORE_DATA_VERSION = 1;

// 実績未入力
export const ACHIEVEMENT_NOT_ENTERED = 0;
// 実績入力済み
export const ACHIEVEMENT_ENTERED = 1;

export const CLOSED_SIDE_MENU_WIDTH = 56;
export const OPENED_SIDE_MENU_WIDTH = 170;

// 運休
export enum SuspensionFlg {
  OFF,
  ON,
}

/**
 * 車種リスト
 */
export const VEHICLE_CLASSES = [
  '2t',
  '2t-L',
  '4t',
  '4tワイド',
  '10t（14）',
  '10t（16）',
  '10t（18）',
  '13t',
  '15t',
  '19t',
  '20t',
  '25t',
];

/**
 * 車両区分タイプリスト
 */
export const VEHICLE_OWNERS = ['自社', '協力会社'];

/**
 * 共有範囲タイプリスト
 */
export const SHARE_SCOPES = ['全て', 'カテゴリ1', 'カテゴリ2', 'カテゴリ3'];

/**
 * 運行マスタ 種別タイプリスト
 */
export const ROUTE_TYPES = ['拠点間', '幹線', '立寄便'];

// 積載率フィルタ
export const LOADING_RATIOS = [0, 25, 50, 75, 100];

// 契約形態
export const CONTRACT_TYPES = ['個建契約', '車建契約'];

// 拠点、種別
export const LOCATION_TYPES = [
  '自社物流拠点',
  '自社営業拠点',
  '他社物流拠点',
  '納品先',
];

export const LOCATION_AREAS = [
  '北海道',
  '東北',
  '関東',
  '中部',
  '近畿',
  '中国',
  '四国',
  '九州沖縄',
];
export const ACHIEVEMENT_ITEMS = [
  {
    category: 'パレット',
    name: '膝',
  },
  {
    category: '',
    name: '腰',
  },
  {
    category: '',
    name: '肩',
  },
  {
    category: '',
    name: '頭',
  },
  {
    category: 'マシン',
    name: '特大',
  },
  {
    category: '',
    name: '大',
  },
  {
    category: '',
    name: '中',
  },
  {
    category: '',
    name: '小',
  },
  {
    category: 'カゴ車',
    name: '項目A',
  },
  {
    category: '',
    name: '項目B',
  },
  {
    category: '',
    name: '項目C',
  },
  {
    category: '',
    name: '項目D',
  },
  {
    category: 'バラ',
    name: '項目A',
  },
  {
    category: '',
    name: '項目B',
  },
  {
    category: '',
    name: '項目C',
  },
  {
    category: '',
    name: '項目D',
  },
];

export const FILTER_CONDITION = {
  ACTIVE: true,
  INACTIVE: false,
};
