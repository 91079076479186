import firebase from 'firebase/app';
import 'firebase/firestore';

import { FIRESTORE_COLLECTION_KEY } from '../constants/Firebase';

export const ClientService = {
  getClientId: async (userId: string): Promise<string> => {
    const clientRef = firebase
      .firestore()
      .collection(FIRESTORE_COLLECTION_KEY.CLIENT);
    const clientSnap = await clientRef
      .where('userIds', 'array-contains', userId)
      .get();

    const clientIds: string[] = [];
    clientSnap.forEach((doc) => clientIds.push(doc.id));

    const [id] = clientIds;
    return id;
  },
};
