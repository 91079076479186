import 'firebase/auth';
import 'firebase/firestore';

import fb from 'firebase/app';

import { FIRESTORE_COLLECTION_KEY } from '../constants/Firebase';
import { BaseMaster, BaseMasterDetail } from '../types/BaseMasterList/types';
import { LOCATION_AREAS, LOCATION_TYPES } from '../constants/shared';
import { LocationFields } from '../types/firestore';

export const BaseMasterService = {
  findAll: async (): Promise<BaseMaster[]> => {
    try {
      const user = fb.auth().currentUser;
      if (!user) {
        throw new Error('Unauthenticated Error');
      }

      const clientSnap = await fb
        .firestore()
        .collection(FIRESTORE_COLLECTION_KEY.CLIENT)
        .where('userIds', 'array-contains', user.uid)
        .get();
      const [doc] = clientSnap.docs;

      const locationSnap = await doc.ref
        .collection(FIRESTORE_COLLECTION_KEY.LOCATION)
        .where('delete_flg', '==', 0)
        .get();

      return locationSnap.docs
        .map((snap) => {
          return { ...snap.data(), id: snap.id } as LocationFields & {
            id: string;
          };
        })
        .map<BaseMaster>((data) => {
          return {
            id: data.id,
            locationCode: data.location_code,
            name: data.name,
            area: LOCATION_AREAS[data.area],
          };
        });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  findById: async (id: string): Promise<BaseMasterDetail> => {
    try {
      const user = fb.auth().currentUser;
      if (!user) {
        throw new Error('Unauthenticated Error');
      }

      const clientSnap = await fb
        .firestore()
        .collection(FIRESTORE_COLLECTION_KEY.CLIENT)
        .where('userIds', 'array-contains', user.uid)
        .get();
      const [doc] = clientSnap.docs;

      const locationDocRef = await doc.ref
        .collection(FIRESTORE_COLLECTION_KEY.LOCATION)
        .doc(id);

      const snap = await locationDocRef.get();
      const location = {
        ...snap.data(),
        id: snap.id,
      } as LocationFields & {
        id: string;
      };

      const locationTypes = location.locationType.map((locationType) => {
        return LOCATION_TYPES[locationType];
      });

      return {
        id: location.id,
        abbreviation: location.abbreviation,
        address: location.address,
        locationTypes,
        jurisdiction: location.jurisdiction,
        name: location.name,
        notes: location.notes,
        operator: location.operator,
        zipCode: location.zipCode,
        area: LOCATION_AREAS[location.area],
        lat: location.lat,
        lon: location.lon,
        locationCode: location.location_code,
      };
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
};
