import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { User } from '../RouteList/types'

interface Props extends RouteComponentProps {
  user: User
  history: any
}

class LoginPage extends Component<Props> {
  componentDidMount() {
    const { user } = this.props

    if (user.uid) {
      this.props.history.push('/beta/route-list')
    }
  }

  onSubmit = (e: any) => {
    const { document } = global

    e.preventDefault()

    const emailEl: any = document.querySelector('[name=email]')
    const passwordEl: any = document.querySelector('[name=password]')

    const email = emailEl.value
    const password = passwordEl.value

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.props.history.push('/beta/route-list')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    return (
      <div className="m-5">
        <form onSubmit={this.onSubmit}>
          <div>
            <span>メールアドレス</span>
            <input name="email" type="text" />
          </div>
          <div>
            <span>パスワード</span>
            <input name="password" type="password" />
          </div>
          <div>
            <button className="btn btn-primary" type="submit">
              ログイン
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ core }: any) => ({
  user: core.user,
})

const mapDispatchToProps = {}

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage)
export default withRouter(connectedLoginPage)
