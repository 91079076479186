import React from 'react';
import { useDispatch } from 'react-redux';

import { useBaseMasters } from '../hooks/useBaseMasters';
import { BaseMasterListLayout } from '../components/BaseMasterList/BaseMasterListLayout';
import { BaseMasterListHeader } from '../components/BaseMasterList/BaseMasterListHeader';
import { BaseMasterTable } from '../components/BaseMasterList/BaseMasterTable';
import { BaseMaster } from '../types/BaseMasterList/types';
import { openModal } from '../reducers/core';

export const BaseMasterListContainer = () => {
  const dispatch = useDispatch();
  const result = useBaseMasters();

  if (result.loading) {
    return <div>Loading...</div>;
  }

  if (!result.data || result.error) {
    return <div>Error</div>;
  }

  const openDetailModal = ({ id }: BaseMaster) => {
    dispatch({
      type: openModal.type,
      payload: { type: 'baseMasterDetail', content: { id } },
    });
  };

  const toAddPage = () => {
    /** TODO React版登録画面実装後useHistory()に差し替える */
    window.location.href = `/masterEdit/#new`;
    return;
  };

  const openAddModal = () => {
    window.alert('開発中');
  };

  const openDownloadModal = () => {
    window.alert('開発中');
  };
  return (
    <BaseMasterListLayout>
      <BaseMasterListHeader
        onAddClick={toAddPage}
        onBulkAddClick={openAddModal}
        onDownloadClick={openDownloadModal}
      />
      <BaseMasterTable baseMasters={result.data} clickRow={openDetailModal} />
    </BaseMasterListLayout>
  );
};
