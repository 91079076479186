import _ from 'lodash';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import {
  CONTRACT_TYPES,
  LOCATION_AREAS,
  VEHICLE_OWNERS,
} from '../../constants/RoutList';

import {
  formatVehicleClass,
  formatRouteType,
  formatShareScope,
  getNameByIndex,
  formatLocationType,
} from '../../formatter/misc';
import { getOverriddenValue } from '../../pages/RouteList/misc';
import { toDateFormat, toTimeFormat } from '../../formatter/DateTimeFormatter';
import { suspendRoutePlan } from '../../pages/RouteList/api';

interface Props {
  content: any;
  onClickClose: () => void;
}

const Table = ({ items }: { items: any[] }) => (
  <div className="d-flex flex-wrap" style={{ width: 400 }}>
    {items.map((item: any, index: number) => (
      <div key={index} style={{ width: 200 }}>
        <span className="mr-2 fs-12 color-gray60" style={{ width: 96 }}>
          {item.l}
        </span>
        <span className="fs-14" style={{ width: 96 }}>
          {item.r}
        </span>
      </div>
    ))}
  </div>
);

const onClickSuspension = (
  uid: string,
  id: string,
  onClickClose: () => void
) => {
  const answer = global.window.confirm(
    'この運行便を運休にします\n\n一度運休にすると元には戻せませんがよろしいですか？'
  );

  if (answer) {
    suspendRoutePlan(uid, id);
    onClickClose();
  }
};

const StyledTypography = styled(Typography)`
  margin-right: 15px;
`;

const RouteModal = ({ content = {}, onClickClose }: Props) => {
  const { uid } = content;
  const {
    // base
    id,
    departure_time_seconds,
    destination_time_seconds,
    shipper_name,
    route_code,
    type,
    // objects
    route_master_ref,
    // destination,
    // departure,
    locations,
  } = content.routePlan;
  const departureLocation: any = _.first(locations);
  const destinationLocation: any = _.last(locations);
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box
          mb={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'baseline'}
        >
          <StyledTypography variant={'h6'}>
            {route_master_ref.name}
          </StyledTypography>
          <Typography variant={'h6'}>(運行便コード：{route_code})</Typography>
        </Box>
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => onClickSuspension(uid, id, onClickClose)}
          >
            運休にする
          </button>
        </div>
      </Box>

      <span className="mr-3 fs-12 color-gray60">種別</span>
      <span className="fs-14">{formatRouteType(type)}</span>

      <hr />

      <div className="d-flex">
        <div className="w-50">
          <div className="fs-14 font-weight-bold mb-3">出発地</div>
          <Table
            items={[
              {
                l: '種別',
                r: formatLocationType(departureLocation.location_type),
              },
              {
                l: '地域',
                r: getNameByIndex(
                  departureLocation?.location.area,
                  LOCATION_AREAS
                ),
              },
              { l: '拠点名', r: departureLocation?.location.abbreviation },
              { l: '出発計画時刻', r: toTimeFormat(departure_time_seconds) },
              { l: '降ろし計画', r: departureLocation.unloading_plan },
              { l: '積み計画', r: departureLocation.loading_plan },
            ]}
          />
        </div>

        <div className="w-50">
          <div className="fs-14 font-weight-bold mb-3">到着地</div>
          <Table
            items={[
              {
                l: '種別',
                r: formatLocationType(destinationLocation.location_type),
              },
              {
                l: '地域',
                r: getNameByIndex(
                  destinationLocation?.location.area,
                  LOCATION_AREAS
                ),
              },
              { l: '拠点名', r: destinationLocation?.location.abbreviation },
              { l: '到着計画時刻', r: toTimeFormat(destination_time_seconds) },
              { l: '降ろし計画', r: destinationLocation.unloading_plan },
              { l: '積み計画', r: destinationLocation.loading_plan },
            ]}
          />
        </div>
      </div>

      <hr />

      {locations
        .slice(1, locations.length - 1)
        .map((location: any, index: number) => (
          <div key={location.id}>
            <div className="fs-14 font-weight-bold mb-3">立寄地{index + 1}</div>
            <Table
              items={[
                {
                  l: '種別',
                  r: formatLocationType(location.location_type),
                },
                { l: '立寄地', r: location.location.abbreviation },
                {
                  l: '到着計画時刻',
                  r: toTimeFormat(location.destination_time_seconds),
                },
                {
                  l: '出発計画時刻',
                  r: toTimeFormat(location.departure_time_seconds),
                },
                { l: '降ろし計画', r: location.unloading_plan },
                { l: '積み計画', r: location.loading_plan },
              ]}
            />
            <hr />
          </div>
        ))}

      <div className="fs-14 font-weight-bold mb-3">運行車両情報</div>
      <Table
        items={[
          {
            l: '運行有効期間',
            r: `${toDateFormat(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'period_from_seconds'
              )
            )}〜${toDateFormat(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'period_to_seconds'
              )
            )}`,
          },
          {
            l: '共有範囲',
            r: formatShareScope(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'share_scope'
              )
            ),
          },
          {
            l: '車両区分',
            r: getNameByIndex(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'vehicle_owner'
              ),
              VEHICLE_OWNERS
            ),
          },
          {
            l: '料金',
            r: getOverriddenValue(content.routePlan, 'route_master_ref', 'fee'),
          },
          {
            l: '車種',
            r: formatVehicleClass(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'vehicle_class'
              )
            ),
          },
          {
            l: '契約形態',
            r: getNameByIndex(
              getOverriddenValue(
                content.routePlan,
                'route_master_ref',
                'contract_type'
              ),
              CONTRACT_TYPES
            ),
          },
          { l: '荷主名', r: shipper_name },
          {
            l: '備考',
            r: getOverriddenValue(
              content.routePlan,
              'route_master_ref',
              'notes'
            ),
          },
        ]}
      />

      <div className="text-center mt-3">
        <button className="btn btn-primary" onClick={onClickClose}>
          閉じる
        </button>
      </div>
    </>
  );
};

export default RouteModal;
