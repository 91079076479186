import React, { useRef, useEffect } from 'react';

import { LOCATION_CARD_HEIGHT } from '../../../constants/RoutList';

const HEIGHT = LOCATION_CARD_HEIGHT - 2;

const ADJUSTMENT_Y = 1;

interface Props {
  startRate: number;
  leftRate: number;
  rightRate: number;
  leftLineStyle: 'solid' | 'dashed';
  rightLineStyle: 'solid' | 'dashed';
  width: number;
  locationWidth: number;
  style?: any;
}

// 実績折れ線
const BlueGauge = ({
  startRate,
  leftRate,
  rightRate,
  leftLineStyle,
  rightLineStyle,
  width,
  locationWidth,
  style,
}: Props) => {
  const ref: any = useRef();
  const ref2: any = useRef();

  const rightRateValue = getRateValue(rightRate);
  const rightY = HEIGHT - Math.round((HEIGHT * rightRateValue) / 100);

  useEffect(() => {
    const ctx = ref.current.getContext('2d');

    ctx.clearRect(0, 0, locationWidth, HEIGHT);

    const leftX = 0;
    const startRateValue = getRateValue(startRate);
    const leftRateValue = getRateValue(leftRate);
    const startY = HEIGHT - Math.round((HEIGHT * startRateValue) / 100);
    const leftY = HEIGHT - Math.round((HEIGHT * leftRateValue) / 100);

    const PADDING = 2;
    ctx.fillStyle = '#DAF5FF';
    ctx.fillRect(leftX, startY, locationWidth / 4, HEIGHT + PADDING);
    ctx.fillRect(locationWidth / 4, leftY, locationWidth / 2, HEIGHT + PADDING);
    ctx.fillRect(
      locationWidth / 2 + locationWidth / 4,
      rightY,
      locationWidth / 4 + 1,
      HEIGHT + PADDING
    );

    ctx.strokeStyle = '#81C9FF';
    ctx.lineWidth = 2;
    leftLineStyle === 'solid' ? ctx.setLineDash([]) : ctx.setLineDash([4, 2]);
    ctx.beginPath();

    // left line
    ctx.moveTo(leftX, startY + ADJUSTMENT_Y);
    ctx.lineTo(leftX + locationWidth / 4, startY + ADJUSTMENT_Y);

    // center line
    ctx.lineTo(leftX + locationWidth / 4, leftY + ADJUSTMENT_Y);
    ctx.lineTo(locationWidth / 2 + locationWidth / 4, leftY + ADJUSTMENT_Y);

    ctx.stroke();

    // right line
    ctx.strokeStyle = '#81C9FF';
    ctx.lineWidth = 2;
    rightLineStyle === 'solid' ? ctx.setLineDash([]) : ctx.setLineDash([4, 2]);
    ctx.beginPath();

    // となりの location にぶつかるまで伸ばす
    ctx.moveTo(locationWidth / 2 + locationWidth / 4, leftY + ADJUSTMENT_Y);
    ctx.lineTo(locationWidth / 2 + locationWidth / 4, rightY + ADJUSTMENT_Y);
    ctx.lineTo(locationWidth + 2, rightY + ADJUSTMENT_Y);

    ctx.stroke();

    const ctx2 = ref2.current.getContext('2d');
    ctx2.strokeStyle = '#81C9FF';
    ctx2.lineWidth = 2;
    leftLineStyle === 'solid' ? ctx2.setLineDash([]) : ctx2.setLineDash([4, 2]);
    ctx2.beginPath();

    ctx2.moveTo(0, 1);
    ctx2.lineTo(width - locationWidth, 1);

    ctx2.stroke();
  });

  return (
    <>
      {/* 拠点上の線 */}
      <canvas
        width={locationWidth + 2}
        height={LOCATION_CARD_HEIGHT}
        style={style}
        ref={ref}
      />
      {/* 拠点間の線 */}
      <canvas
        width={width - locationWidth + 2}
        height={2}
        style={{ ...style, top: rightY, left: locationWidth }}
        ref={ref2}
        onClick={(e) => e.stopPropagation()}
      />
    </>
  );
};

export default BlueGauge;

/**
 * 下限・上限の範囲内でレートの値を取得する
 */
const getRateValue = (rate: number): number => {
  const UPPER_RATE = 100;
  const LOWER_RATE = 0;
  if (rate < LOWER_RATE) {
    return LOWER_RATE;
  }
  if (rate > UPPER_RATE) {
    return UPPER_RATE;
  }
  return rate;
};
