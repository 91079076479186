import { VFC, useRef } from 'react';
interface Props {
  label: string;
  onClick?: () => void;
  outLine?: boolean;
  width?: number;
  type?: 'button' | 'submit';
}
export const Button: VFC<Props> = ({
  label,
  outLine = false,
  onClick,
  width = 184,
  type = 'button',
}) => {
  const clearButtonRef = useRef<HTMLButtonElement | null>(null);
  clearButtonRef.current?.blur();
  return (
    <button
      className={outLine ? 'btn btn-outline-primary' : 'btn btn-primary'}
      style={{ borderRadius: 30, fontSize: 14, width }}
      onClick={onClick}
      type={type}
      ref={clearButtonRef}
    >
      {label}
    </button>
  );
};
