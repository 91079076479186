import React, { VFC } from 'react';
import { Grid } from '@material-ui/core';

import { DataModalListItemLabel } from './DataModalListItemLabel';
import { DataModalListItemValue } from './DataModalListItemValue';

type Props = {
  label: string | JSX.Element;
  value?: string | number;
  fullWidth?: boolean;
  bold?: boolean;
};

export const DataModalListItem: VFC<Props> = ({
  label,
  value,
  fullWidth,
  bold,
}) => {
  return (
    <>
      {fullWidth ? (
        <>
          <Grid item xs={2}>
            <DataModalListItemLabel bold={bold} label={label} />
          </Grid>
          <Grid item xs={10}>
            <DataModalListItemValue bold={bold} value={value} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={2}>
            <DataModalListItemLabel bold={bold} label={label} />
          </Grid>
          <Grid item xs={4}>
            <DataModalListItemValue bold={bold} value={value} />
          </Grid>
        </>
      )}
    </>
  );
};
