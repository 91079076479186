import React, { VFC } from 'react';
import { Box, Grid } from '@material-ui/core';

type Props = { title: string };

export const DataModalListHeading: VFC<Props> = ({ title }) => (
  <Grid item xs={12}>
    <Box fontSize={15} fontWeight={'fontWeightBold'}>
      {title}
    </Box>
  </Grid>
);
