import { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { DELETE_FLG } from '../../constants/Firebase';
import { Locations } from '../../types/BaseView/types';
import { locationsConverter } from '../../util/BaseView/LocationsConverter';

export const useLocation = (clientId?: string) => {
  const [data, setData] = useState<Locations>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (!clientId) {
      return;
    }
    firebase
      .firestore()
      .collection(`client/${clientId}/location`)
      .where('delete_flg', '==', DELETE_FLG.NOT_DELETED)
      .withConverter(locationsConverter)
      .onSnapshot(
        (snapshot) => {
          const locations: Locations = [];
          snapshot.forEach((doc) => locations.push(doc.data()));
          setLoading(false);
          setData(locations);
        },
        (error) => {
          console.error(error);
          setLoading(false);
          setError(error);
        }
      );
  }, [clientId]);

  return {
    data,
    loading,
    error,
  };
};
