import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Box } from '@material-ui/core';

import ModalBase from './components/ModalBase';
import LoginPage from './pages/Login/LoginPage';
import RouteListPage from './pages/RouteList/RouteListPage';
import ShipperListPage from './pages/Shipper/ShipperListPage';
import ShipperEditPage from './pages/Shipper/ShipperEditPage';
import { BaseViewPage } from './pages/BaseView/BaseViewPage';
import { updateUser, closeModal, openMenu, closeMenu } from './reducers/core';
import { LoginStatus, User } from './pages/RouteList/types';
import { ClientContextProvider } from './contexts/ClientContext';
import Progress from './components/Progress';
import { RouteMasterListPage } from './pages/RouteMasterList/RouteMasterListPage';
import { BaseMasterListPage } from './pages/BaseMasterList/BaseMasterListPage';
import { SideMenuContainer } from './containers/SideMenuContainer';

const onError = (error: any) => {
  alert(JSON.stringify(error));
};

interface Props {
  user: User;
  modal: any;
  visibleMenu: boolean;
  visibleProgress: boolean;
  closeModal: () => void;
  openMenu: () => void;
  closeMenu: () => void;
  updateUser: (user: User) => void;
}

let isFirstCall = true;

const Base = ({
  user,
  modal,
  visibleProgress,
  closeModal,
  updateUser,
}: Props) => {
  useEffect(() => {
    if (!isFirstCall) {
      return;
    }

    isFirstCall = false;

    firebase.auth().onAuthStateChanged((anUser: any) => {
      if (anUser) {
        // User is signed in.

        const newUser: User = {
          uid: anUser.uid,
          email: anUser.email,
          displayName: anUser.displayName,
          loginStatus: LoginStatus.SUCCEEDED,
        };

        updateUser(newUser);
      } else {
        // 現在のページが /beta/login の場合は何もしない

        // No user is signed in.
        console.log('No user is signed in');
        global.location.href = '/login';
      }
    });
  }, [updateUser]);

  if (user.loginStatus === LoginStatus.NOT_LOGIN) {
    return <div>loading...</div>;
  } else if (user.loginStatus === LoginStatus.FAILED) {
    global.location.href = '/login';
    return null;
  }

  return (
    <div onError={onError}>
      <ClientContextProvider>
        <BrowserRouter>
          <Box display={'flex'}>
            <SideMenuContainer />
            <Box width={1}>
              <Switch>
                <Route path="/beta" exact component={LoginPage} />
                <Route path="/beta/login" component={LoginPage} />
                <Route path="/beta/route-list" component={RouteListPage} />
                <Route
                  path="/beta/route-master-list"
                  component={RouteMasterListPage}
                />
                <Route path="/beta/shippers" component={ShipperListPage} />
                <Route
                  path="/beta/shippers/(:shipperId)"
                  component={ShipperEditPage}
                />
                <Route path="/beta/baseView" component={BaseViewPage} />
                <Route
                  path="/beta/base-master-list"
                  component={BaseMasterListPage}
                />
              </Switch>
            </Box>
          </Box>
        </BrowserRouter>

        <ModalBase
          visible={modal.visible}
          type={modal.type}
          content={modal.content}
          onClickClose={() => closeModal()}
        />

        {visibleProgress && <Progress />}
      </ClientContextProvider>
    </div>
  );
};

const mapStateToProps = ({ core }: any) => ({
  user: core.user,
  modal: core.modal,
  visibleMenu: core.visibleMenu,
  visibleProgress: core.visibleProgress,
});

const mapDispatchToProps = {
  closeModal,
  openMenu,
  closeMenu,
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Base);
