import React from 'react';
import { useDispatch } from 'react-redux';

import { RouteMaster } from '../types/RouteMasterList/types';
import { useRouteMasters } from '../hooks/useRouteMasters';
import { RouteMasterListLayout } from '../components/RouteMasterList/RouteMasterListLayout';
import { RouteMasterTable } from '../components/RouteMasterList/RouteMasterTable';
import { RouteMasterListHeader } from '../components/RouteMasterList/RouteMasterListHeader';
import { openModal } from '../reducers/core';

export const RouteMasterListContainer = () => {
  const dispatch = useDispatch();
  const { routeMasters, loading, error } = useRouteMasters();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!routeMasters || error) {
    return <div>Error</div>;
  }

  const openDetailModal = ({ id }: RouteMaster) => {
    dispatch({
      type: openModal.type,
      payload: { type: 'routeMasterDetail', content: { id } },
    });
  };

  const toAddPage = () => {
    /** TODO React版登録画面実装後useHistory()に差し替える */
    window.location.href = `/routeMasterEdit/#new`;
    return;
  };

  const openAddModal = () => {
    window.alert('開発中');
  };

  const openDownloadModal = () => {
    window.alert('開発中');
  };

  return (
    <RouteMasterListLayout>
      <RouteMasterListHeader
        onAddClick={toAddPage}
        onBulkAddClick={openAddModal}
        onDownloadClick={openDownloadModal}
      />
      <RouteMasterTable
        routeMasters={routeMasters}
        clickRow={openDetailModal}
      />
    </RouteMasterListLayout>
  );
};
