export const ACHIEVEMENT = {
  ACHIEVED: 1,
  NOT_ACHIEVED: 0,
};

export const INITIAL_LOCATION_KEY = 'initialLocation';

export const DAY_START_HOUR = 4;

export const TABLE_COLUMNS = [
  '予定',
  '車種',
  '便名',
  '協力会社',
  '到着時積載率',
  '出発時積載率',
  '',
];
