import React, { VFC } from 'react';
import styled from 'styled-components';

import { AppBar, Button, Toolbar } from '@material-ui/core';
import { CloudDownload, CloudUpload, Edit } from '@material-ui/icons';

const iconColor = 'gray';

const StyledDownloadIcon = styled(CloudDownload)`
  color: ${iconColor};
`;

const StyledUploadIcon = styled(CloudUpload)`
  color: ${iconColor};
`;

const StyledEditIcon = styled(Edit)`
  color: ${iconColor};
`;

const StyledAppbar = styled(AppBar)`
  box-shadow: none;
  background-color: #fff;
  border-bottom: 1px solid rgb(224 224 224);
`;

const StyledButton = styled(Button)({
  cursor: 'not-allowed',
  '&:focus': {
    outline: 'none',
  },
});

type Props = {
  onAddClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onBulkAddClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onDownloadClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const BaseMasterListHeader: VFC<Props> = ({
  onAddClick,
  onBulkAddClick,
  onDownloadClick,
}) => (
  <StyledAppbar position={'static'}>
    <Toolbar variant={'dense'}>
      <StyledButton onClick={onAddClick} startIcon={<StyledEditIcon />}>
        個別登録
      </StyledButton>
      <StyledButton onClick={onBulkAddClick} startIcon={<StyledUploadIcon />}>
        一括登録
      </StyledButton>
      <StyledButton
        onClick={onDownloadClick}
        startIcon={<StyledDownloadIcon />}
      >
        登録フォーマット取得
      </StyledButton>
    </Toolbar>
  </StyledAppbar>
);
