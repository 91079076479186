import moment from 'moment';

export const PERIOD_TYPES = {
  DESIGNATION: 'DESIGNATION',
  FIXED: 'FIXED',
} as const;

// 本日を含めて過去10日間をダウンロード可能日数とする
export const TODAY = 1;
export const DEFAULT_PERIOD_DAY = 10;
// 本日を含める為9日前を初期値とする
export const DEFAULT_PERIOD_COUNT = DEFAULT_PERIOD_DAY - TODAY;

export const DEFAULT_PERIOD_VALUE = {
  from: moment().add(-DEFAULT_PERIOD_COUNT, 'day').toDate(),
  to: moment().toDate(),
};

export const CSV_NAME_PREFIX = '運行実績_';
export const CSV_NAME_FORMAT = 'YYYYMMDD';

// DateRangePickerの初期表示月
export const INITIAL_MONTH = moment().add(-1, 'month').toDate();
// DataRangePickerで選択できる最古の月
export const FROM_MONTH = new Date('2020-01-01');
