import styled from 'styled-components';

const MAX_LINE = 2;
export const BaseMasterTableCellText = styled.div`
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${MAX_LINE};
  -webkit-box-orient: vertical;
`;
