import React, { VFC } from 'react';

export const Error: VFC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }}
  >
    <p>データの取得に失敗しました</p>
  </div>
);
