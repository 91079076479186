import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  label: string;
  value?: boolean;
  onChange: (isActive: boolean) => void;
};

export const ToggleButton = memo(({ label, onChange, value }: Props) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setIsSelected(value);
    }
  }, [value]);

  const toggle = useCallback(() => {
    setIsSelected(!isSelected);
    onChange(!isSelected);
  }, [isSelected, onChange]);

  return (
    <button
      className="mr-2"
      type="button"
      style={{
        width: 120,
        height: 36,
        border: `1px solid ${isSelected ? '#247CDB' : 'rgba(0, 0, 0, 0.12)'}`,
        borderRadius: 50,
        outline: 'none',
        fontSize: 12,
        backgroundColor: isSelected ? '#DAF5FF' : '#fff',
        color: isSelected ? '#247CDB' : 'rgba(0, 0, 0, 0.6)',
      }}
      onClick={toggle}
    >
      {label}
    </button>
  );
});
