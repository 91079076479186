import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import 'firebase/auth';
import 'firebase/firestore';
import fb from 'firebase/app';
import { useDispatch } from 'react-redux';

import { useLocalStorage } from './useLocalStorage';
import { SIDE_MENU_STATUS_KEY, SIDE_MENU_STATE } from '../constants/SideMenu';
import { closeMenu, openMenu } from '../reducers/core';

export const useSideMenu = (): {
  drawerOpen: boolean;
  masterListOpen: boolean;
  handleDrawer: () => void;
  handleMasterList: () => void;
  handleRouteChange: (path: string) => void;
  handleLogout: () => void;
} => {
  const history = useHistory();
  const { setValue, storeValue } = useLocalStorage<boolean>(
    SIDE_MENU_STATUS_KEY,
    SIDE_MENU_STATE.CLOSE
  );
  // ローカルストレージに保存した開閉状態をセット
  const [drawerOpen, setDrawerOpen] = useState(storeValue);
  const [masterListOpen, setMasterListOpen] = useState(SIDE_MENU_STATE.CLOSE);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setValue(!drawerOpen);
  };

  useEffect(() => {
    // ドロワー展開時にマスタ管理も展開する
    setMasterListOpen(drawerOpen);
  }, [drawerOpen]);

  const handleMasterList = () => {
    if (drawerOpen) {
      // ドロワーが開いている場合、マスタ管理を開閉できる
      setMasterListOpen(!masterListOpen);
    } else {
      // ドロワーが開いていない場合、ドロワーを展開する
      setDrawerOpen(SIDE_MENU_STATE.OPEN);
      setValue(SIDE_MENU_STATE.OPEN);
    }
  };

  const handleRouteChange = (path: string) => history.push(path);

  const handleLogout = async () => {
    try {
      await fb.auth().signOut();
      window.location.href = '/login';
    } catch (error) {
      console.log(error);
    }
  };

  // 運行一覧表ページ用に store を更新する
  const dispatch = useDispatch();
  dispatch(drawerOpen ? openMenu() : closeMenu());

  return {
    drawerOpen,
    masterListOpen,
    handleDrawer,
    handleMasterList,
    handleRouteChange,
    handleLogout,
  };
};
