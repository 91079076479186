import { useCallback, useRef, useState } from 'react';
import { CSVUploadResponse } from '../../types/Services/achievement';
import { AchievementService } from '../../services/AchievementService';

export const useAchievementUpload: () => {
  data: CSVUploadResponse | undefined;
  loading: boolean;
  error: Error | undefined;
  uploadCSV: (csvString: string) => Promise<void>;
} = () => {
  const responseRef = useRef<CSVUploadResponse | undefined>(undefined);
  const errorRef = useRef<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadCSV = useCallback(async (csvString: string) => {
    setLoading(true);
    try {
      responseRef.current = await AchievementService.uploadCSV(csvString);
    } catch (error) {
      errorRef.current = error;
    }
    setLoading(false);
  }, []);

  return {
    data: responseRef.current,
    error: errorRef.current,
    loading,
    uploadCSV,
  };
};
