import { Component } from 'react'

interface Props {}

class ShipperEditPage extends Component<Props> {
  onSubmit = (e: any) => {
    e.preventDefault()
    // TODO

    // call firebase api
  }

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div>
            <span>荷主コード</span>
            <input type="text" />
          </div>
          <div>
            <span>荷主名</span>
            <input type="text" required />
          </div>

          <button type="submit" className="btn btn-primary">
            保存
          </button>
        </form>
      </div>
    )
  }
}

export default ShipperEditPage
