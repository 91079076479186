import React, { VFC, useRef, useMemo, useEffect } from 'react';

import { AssignmentOutlined } from '@material-ui/icons';
import moment from 'moment';

import { TABLE_COLUMNS } from '../../constants/BaseView';
import {
  BaseViewLocations,
  SearchConditions,
} from '../../types/BaseView/types';

import { LoadingRate } from './LoadingRate';
import { toTimeFormat } from '../../formatter/DateTimeFormatter';

interface TableProps {
  baseViewLocations: BaseViewLocations;
  conditions: SearchConditions;
}

export const Table: VFC<TableProps> = ({ baseViewLocations, conditions }) => {
  const isEmpty = baseViewLocations.length === 0;
  const ref = useRef<HTMLTableRowElement | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [conditions.date, conditions.locationName]);

  useEffect(() => {
    if (!ref.current || !conditions.pressedTime) {
      return;
    }
    if (conditions.pressedTime || ref) {
      const tableHeaderHight = 45;
      const top = ref.current.offsetTop - tableHeaderHight;
      window.scroll({ top, behavior: 'smooth' });
    }
  }, [conditions.pressedTime]);

  const scrollTarget = useMemo(() => {
    const pressedTime = moment(conditions.pressedTime);
    const destinationTimes = baseViewLocations.map((data) => {
      const destinationTime = moment.unix(data.destinationTime);
      // ボタンを押した時の時刻より、後もしくは同じ場合、trueを返す
      const recentTime = pressedTime.isSameOrBefore(destinationTime);
      return [
        {
          destinationTime: data.destinationTime,
          recentTime: recentTime,
        },
      ];
    });

    return destinationTimes.findIndex((data) => data[0].recentTime === true);
  }, [baseViewLocations, conditions.pressedTime]);

  return (
    <>
      <table
        className="table m-0"
        style={{
          fontFamily: 'Noto Sans JP',
          fontSize: 14,
          color: 'rgba(0, 0, 0, 0.87)',
          borderCollapse: 'separate',
          borderSpacing: 0,
        }}
      >
        <thead>
          <tr>
            {TABLE_COLUMNS.map((column) => {
              return (
                <th
                  key={column}
                  className="text-center"
                  style={{
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.6)',
                    position: 'sticky',
                    top: 64,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #dee2e6',
                  }}
                >
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {baseViewLocations.map((data, idx) => {
            return (
              <tr
                className="text-center"
                key={idx}
                ref={idx === scrollTarget ? ref : null}
                style={
                  idx === scrollTarget && conditions.pressedTime
                    ? { backgroundColor: '#DAF5FF' }
                    : { backgroundColor: '#fff' }
                }
              >
                <td>
                  <div>{toTimeFormat(data.destinationTime)}</div>
                  <div style={{ fontSize: 10, color: 'rgba(0, 0, 0, 0.6)' }}>
                    {moment.unix(data.destinationTime).format('MM/DD')}
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  {data.vehicleClass}
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  {data.name}
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  {data.partnerName}
                </td>
                <td>
                  <LoadingRate
                    plan={data.destinationCapacityPlan}
                    achievement={data.destinationCapacity}
                  />
                </td>
                <td>
                  <LoadingRate
                    plan={data.departureCapacityPlan}
                    achievement={data.departureCapacity}
                  />
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {!data.isAchieved && (
                    <span
                      style={{
                        color: '#247CDB',
                      }}
                    >
                      未入力
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isEmpty && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'calc(100vh - 180px)',
          }}
        >
          <AssignmentOutlined
            style={{ fontSize: 66, color: 'rgba(0, 0, 0, 0.12)' }}
          />
          <div
            className="mt-4"
            style={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: 14 }}
          >
            該当する運行便はありません
          </div>
        </div>
      )}
    </>
  );
};
