import React, { VFC } from 'react';
import { TableCell } from '@material-ui/core';

import { ShapeType } from '../../types/shared';
import { RouteMasterTableCellText } from './RouteMasterTableCellText';

export const RouteMasterTableShapeTypeCell: VFC<{
  shapeTypes: ShapeType[];
}> = ({ shapeTypes }) => {
  const DISPLAY_SHAPES = ['バン', 'ウイング', '平ボディ'];
  const displayShapeTypes = shapeTypes.filter((t) =>
    DISPLAY_SHAPES.includes(t)
  );
  if (!displayShapeTypes.length) {
    return <TableCell>他</TableCell>;
  }
  const text =
    displayShapeTypes.length === shapeTypes.length
      ? displayShapeTypes.join('/')
      : `${displayShapeTypes.join('/')}/他`;
  return (
    <TableCell>
      <RouteMasterTableCellText>{text}</RouteMasterTableCellText>
    </TableCell>
  );
};
