import { connect } from 'react-redux';
import moment from 'moment';

import { LOCATION_TYPES } from '../../constants/RoutList';
import { fetchLocationMasters } from '../../pages/RouteList/api';
import { updateLocationMasters } from '../../reducers/routeList';
import { User } from '../../pages/RouteList/types';
import { removeHeadZero } from '../../formatter/misc';
import {
  findConflictedLocation,
  isIncludesLocation,
} from '../../services/Date';
import { RoutePlanService } from '../../services/RoutePlanService';

interface Props {
  user: User;
  content: any;
  locationMasters: any[];
  onClickClose: () => void;
  updateLocationMasters: (a: any[]) => void;
}

// 立寄地の追加モーダル
const StopAddingModal = ({
  user,
  content,
  locationMasters,
  onClickClose,
  updateLocationMasters,
}: Props) => {
  const { routePlan, fromTime, toTime } = content;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const inputEls: NodeListOf<HTMLInputElement> = global.document.querySelectorAll(
      '.js-stop-adding-modal input'
    );

    const selectEls: NodeListOf<HTMLSelectElement> = global.document.querySelectorAll(
      '.js-stop-adding-modal select'
    );

    const inputValues: any = {};

    inputEls.forEach((el: HTMLInputElement) => {
      const value = ['departure_time', 'destination_time'].includes(el.name)
        ? el.value.padStart(5, '0')
        : Number(el.value);

      inputValues[el.name] = value;
    });

    selectEls.forEach((el: HTMLSelectElement) => {
      if (el.name === 'location_type') {
        return;
      }

      inputValues[el.name] = el.value;
    });

    const routePlanDepartureTime = moment.unix(routePlan.departure_time_seconds);
    const destinationTime = moment(
      `${routePlanDepartureTime.format('YYYY-MM-DD')} ${inputValues.destination_time}`
    );
    const departureTime = moment(
      `${routePlanDepartureTime.format('YYYY-MM-DD')} ${inputValues.departure_time}`
    );

    if (destinationTime.isBefore(routePlanDepartureTime)) {
      destinationTime.add(1, 'day');
    }
    if (departureTime.isBefore(routePlanDepartureTime)) {
      departureTime.add(1, 'day');
    }

    const leftLocation = findConflictedLocation(
      routePlan.locations,
      destinationTime
    );

    if (Boolean(leftLocation)) {
      alert(
        `選択された到着時刻 ${removeHeadZero(
          inputValues.destination_time
        )} は「${leftLocation.location.name}」と重複しています`
      );
      return;
    }

    const rightLocation = findConflictedLocation(
      routePlan.locations,
      departureTime
    );

    if (Boolean(rightLocation)) {
      alert(
        `選択された出発時刻 ${removeHeadZero(
          inputValues.departure_time
        )} は「${rightLocation.location.name}」と重複しています`
      );
      return;
    }

    const result = isIncludesLocation(
      destinationTime,
      departureTime,
      routePlan.locations
    );

    if (result) {
      alert('指定された時間帯には既に立寄地が存在します');
      return;
    }

    await RoutePlanService.addTruckStop({
      routePlanId: routePlan.id,
      inputData: {
        locationId: inputValues.location_id,
        loadingPlan: inputValues.loading_plan,
        unloadingPlan: inputValues.unloading_plan,
        destinationTime: destinationTime.toDate(),
        departureTime: departureTime.toDate(),
      },
    });

    onClickClose();
  };

  const onChangeLocationType = async (e: any) => {
    const { value } = e.target;
    const locationType = Number(value);

    if (!value) {
      return;
    }

    const ref: any = await fetchLocationMasters(user.uid);
    const snapshot: any = await ref.get();

    const locations = snapshot.docs
      .map((doc: any) => ({ id: doc.id, ...doc.data() }))
      .filter(
        (location: any) =>
          location.locationType && location.locationType.includes(locationType)
      );

    updateLocationMasters(locations);
  };

  return (
    <div className="js-stop-adding-modal">
      <h3 className="text-center mb-3">立寄地の追加</h3>
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <label
            htmlFor="departure"
            className="col-sm-2 col-form-label text-right"
          >
            種別
          </label>
          <div className="col-sm-4">
            <select name="location_type" onChange={onChangeLocationType}>
              <option value=""></option>
              {LOCATION_TYPES.map((locationType: string, index: number) => (
                <option key={locationType} value={index}>
                  {locationType}
                </option>
              ))}
            </select>
          </div>
          <label
            htmlFor="departure"
            className="col-sm-2 col-form-label text-right"
          >
            立寄地
          </label>
          <div className="col-sm-4">
            <select name="location_id" required>
              {locationMasters.map((location: any) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="inputEmail4"
            className="col-sm-2 col-form-label text-right"
          >
            到着時刻
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              name="destination_time"
              defaultValue={removeHeadZero(fromTime)}
              placeholder="00:00"
              required
            />
            {/* <TimeSelect name="destination_time" value={fromTime} required /> */}
          </div>
          <label
            htmlFor="inputEmail3"
            className="col-sm-2 col-form-label text-right"
          >
            出発時刻
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              name="departure_time"
              defaultValue={removeHeadZero(toTime)}
              placeholder="00:00"
              required
            />
            {/* <TimeSelect name="departure_time" value={toTime} required /> */}
          </div>
        </div>

        <hr />

        <div className="row mb-3">
          <span className="col-sm-2 text-right">降ろし計画</span>
          <span className="col-sm-4">
            <input
              type="number"
              name="unloading_plan"
              required
              defaultValue="0"
            />
          </span>
          <span className="col-sm-2 text-right">積み計画</span>
          <span className="col-sm-4">
            <input
              type="number"
              name="loading_plan"
              required
              defaultValue="0"
            />
          </span>
        </div>

        <hr />

        <div className="text-center">
          <button
            type="submit"
            className="btn btn-secondary mr-4"
            onClick={() => onClickClose()}
          >
            キャンセル
          </button>
          <button type="submit" className="btn btn-primary">
            追加する
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({
  core: { user },
  routeList: { locationMasters },
}: any) => ({
  user,
  locationMasters,
});

const mapDispatchToProps = {
  updateLocationMasters,
};

export default connect(mapStateToProps, mapDispatchToProps)(StopAddingModal);
