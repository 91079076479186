import { useEffect, useState } from 'react';
import { BaseMasterDetail } from '../types/BaseMasterList/types';
import { BaseMasterService } from '../services/BaseMasterService';

import { HookResultType } from '../types/hooks';

export const useBaseMasterForm = (
  id: string
): HookResultType<BaseMasterDetail> => {
  const [result, setResult] = useState<HookResultType<BaseMasterDetail>>({
    loading: true,
  });

  useEffect(() => {
    BaseMasterService.findById(id)
      .then((res) =>
        setResult({
          loading: false,
          data: res,
          error: undefined,
        })
      )
      .catch((error) =>
        setResult({
          loading: false,
          data: undefined,
          error: error,
        })
      );
  }, [id]);

  return result;
};
