import React, { useEffect, useState, VFC } from 'react';

import 'moment/locale/ja';

import 'react-day-picker/lib/style.css';
import {
  createMuiTheme,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Today } from '@material-ui/icons';

import 'fontsource-roboto';
import { DateRangePicker } from './DateRangePicker';
import {
  INITIAL_MONTH,
  FROM_MONTH,
  PERIOD_TYPES,
} from '../constants/AchievementDownload';
import { Button } from './shared/Button';
import { useDispatch } from 'react-redux';
import { closeModal } from '../reducers/core';
import { formatRangeFieldValue } from '../util/Achievement';
import { useAchievementDownloadForm } from '../hooks/Achievement/useAchievementDownloadForm';
import { FieldError } from 'react-hook-form';

interface Props {
  onClickClose: () => void;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#247CDB',
    },
  },
});

export const AchievementDownload: VFC<Props> = ({ onClickClose }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    dateRangeValue,
    completed,
    changeDataRange,
    isDesignation,
    submit,
    errors,
    control,
    Controller,
  } = useAchievementDownloadForm();

  const handleChangeDateRange = (from: Date, to: Date) => {
    changeDataRange(from, to);
    setOpen(false);
  };

  useEffect(() => {
    if (completed) {
      dispatch({
        type: closeModal.type,
        payload: { type: 'achievementDownload' },
      });
    }
  }, [completed, dispatch]);

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ margin: 16, fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}>
        <Dialog
          open={open}
          maxWidth={'xl'}
          onBackdropClick={() => setOpen(false)}
        >
          <DialogContent>
            <DateRangePicker
              onChange={handleChangeDateRange}
              initialMonth={INITIAL_MONTH}
              fromMonth={FROM_MONTH}
            />
          </DialogContent>
        </Dialog>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          CSVデータをダウンロードする
        </div>
        <form onSubmit={submit}>
          <div style={{ marginTop: 33 }}>
            <Controller
              name={'periodType'}
              control={control}
              as={
                <RadioGroup aria-label={'periodType'}>
                  <FormControlLabel
                    control={
                      <Radio value={PERIOD_TYPES.FIXED} color={'primary'} />
                    }
                    label={'直近10日間'}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        value={PERIOD_TYPES.DESIGNATION}
                        color={'primary'}
                      />
                    }
                    label={'期間指定'}
                  />
                </RadioGroup>
              }
            />
          </div>
          <div style={{ marginLeft: 32, marginBottom: 32 }}>
            <TextField
              variant={'outlined'}
              value={formatRangeFieldValue(dateRangeValue)}
              style={{ width: 330 }}
              error={!!errors?.dateRange}
              helperText={
                ((errors?.dateRange as unknown) as FieldError)?.message
              }
              onClick={() => setOpen(isDesignation)}
              disabled={true}
              InputProps={{
                style: {
                  height: 36,
                  fontSize: 12,
                  color: 'rgba(0, 0, 0, 0.6)',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Today />
                  </InputAdornment>
                ),
              }}
            />
            {!errors.dateRange && (
              <div style={{ fontSize: 12, marginTop: 8 }}>
                ※最大で10日間の期間指定が可能です
              </div>
            )}
          </div>
          <Divider />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 32,
            }}
          >
            <Button
              label={'キャンセル'}
              outLine
              onClick={() => onClickClose()}
            />
            <Button label={'ダウンロードする'} type="submit" />
          </div>
          <Controller
            name={'dateRange'}
            control={control}
            defaultValue={''}
            render={({ value }) => <input type={'hidden'} value={value} />}
          />
        </form>
      </div>
    </MuiThemeProvider>
  );
};
