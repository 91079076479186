import React, { FC } from 'react';
import { DialogTitle, Divider } from '@material-ui/core';
import styled from 'styled-components';

const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;
`;

export const DataModalHeader: FC = ({ children }) => (
  <StyledDialogTitle>
    {children}
    <Divider />
  </StyledDialogTitle>
);
