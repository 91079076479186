import { useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, date, object, string } from 'yup';
import moment from 'moment';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { DateRange, FormType } from '../../types/AchievementDownload/types';

import {
  DEFAULT_PERIOD_DAY,
  DEFAULT_PERIOD_VALUE,
  PERIOD_TYPES,
} from '../../constants/AchievementDownload';

import { useAchievementDownload } from './useAchievementDownload';

const schema = object().shape({
  periodType: string(),
  dateRange: array()
    .of(date())
    .test('overLimit', '*10日間以上の期間指定は出来ません', function (value) {
      const [from, to] = value as DateRange;
      // From - Toの差分 + 1日が選択した日付の数
      const selectedDays = moment(to).diff(from, 'days') + 1;
      return selectedDays <= DEFAULT_PERIOD_DAY;
    }),
});

export const useAchievementDownloadForm = () => {
  const {
    downloadCSV,
    completed,
    error: downloadError,
  } = useAchievementDownload();

  const {
    handleSubmit,
    control,
    setValue,
    errors,
    trigger,
    setError,
  } = useForm<FormType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      periodType: PERIOD_TYPES.FIXED,
      dateRange: [DEFAULT_PERIOD_VALUE.from, DEFAULT_PERIOD_VALUE.to],
    },
  });

  const dateRangeValue = (useWatch({
    control,
    name: 'dateRange',
  }) as unknown) as DateRange;

  const periodTypeValue = useWatch({
    control,
    name: 'periodType',
  });

  useEffect(() => {
    if (periodTypeValue === PERIOD_TYPES.FIXED) {
      setValue('dateRange', [
        DEFAULT_PERIOD_VALUE.from,
        DEFAULT_PERIOD_VALUE.to,
      ]);
    }
    trigger('dateRange');
  }, [setValue, trigger, periodTypeValue]);

  useEffect(() => {
    if (downloadError) {
      setError('dateRange', {
        type: 'download error',
        message: downloadError?.message,
      });
    }
  }, [setError, downloadError]);

  const changeDataRange = useCallback(
    (from: Date, to: Date) => {
      setValue('dateRange', [from, to]);
      trigger('dateRange');
    },
    [setValue, trigger]
  );

  const onValid: SubmitHandler<FormType> = async ({ dateRange }) => {
    const [from, to] = dateRange;
    await downloadCSV(from, to);
  };

  const submit = handleSubmit(onValid);

  return {
    changeDataRange,
    dateRangeValue,
    periodTypeValue,
    submit,
    errors,
    completed,
    control,
    Controller,
    isDesignation: periodTypeValue === PERIOD_TYPES.DESIGNATION,
  };
};
