import React, { VFC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

type Props = {
  message: string;
};
const StyledCircularProgress = styled(CircularProgress)`
  color: #247cdb;
`;

export const DataModalLoading: VFC<Props> = ({ message }: Props) => (
  <Box textAlign={'center'} p={5}>
    <StyledCircularProgress />
    <Box mt={2} fontSize={13} color={'gray'}>
      {message}中のためしばらくお待ち下さい
    </Box>
  </Box>
);
