import React from 'react';
import _ from 'lodash';

import {
  FAR_LEFT_TIME,
  FAR_RIGHT_TIME,
  HOURS_OF_DAY,
  WIDTH_OF_1HOUR,
} from '../../../constants/RoutList';

// 左端の時刻
const count = FAR_RIGHT_TIME - FAR_LEFT_TIME;

const hours = _.times(count).map((hour: number) => {
  const n = FAR_LEFT_TIME + hour;
  return n < HOURS_OF_DAY ? n : n - HOURS_OF_DAY;
});

interface Props {
  displayWidth: number;
  style: any;
}

const TimeRow = ({ displayWidth, style }: Props) => (
  <div
    className="d-flex"
    style={{
      backgroundColor: '#F8F5F1',
      ...style,
    }}
  >
    {hours.map((hour: number, index: number) => (
      <div
        key={`${hour}-${index}`}
        className="text-center"
        style={{ fontSize: 12, width: WIDTH_OF_1HOUR * (60 / displayWidth) }}
      >
        {hour}:00
      </div>
    ))}
  </div>
);

export default TimeRow;
