import { useCallback, useEffect, useState, VFC } from 'react';

import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { Button } from '../shared/Button';
import { FILE_ERRORS } from '../../constants/AchievementUpload';
import {convert, detect} from "encoding-japanese";

interface Props {
  onClickClose: () => void;
  error: Error | undefined;
  uploadCSV: (csvString: string) => Promise<void>;
}

export const AchievementUploadFileSelect: VFC<Props> = ({
  onClickClose,
  error,
  uploadCSV,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [csvFile, setCsvFile] = useState<string | undefined>(undefined);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setErrors([]);

      if (fileRejections.length) {
        const errorMessages = fileRejections
          .reduce<FileError[]>((prev, cur) => {
            return [...prev, ...cur.errors];
          }, [])
          .map((err) => err.code);
        const errorCodes = Array.from(new Set(errorMessages));
        setErrors(errorCodes);
        setCsvFile(undefined);
        return;
      }

      const [file] = acceptedFiles;
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const codes = new Uint8Array(event!.target!.result as Uint8Array);
        const str = detect(codes, 'SJIS') ? convert(codes, {
          to: 'UNICODE',
          from: 'SJIS',
          type: 'string'
        }) : codes
        setCsvFile(str as string);
      };
    },
    []
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    open,
  } = useDropzone({
    onDrop,
    accept: '.csv',
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
  });

  const upload = async () => {
    if (!csvFile) {
      setErrors(['empty-file']);
      return;
    }
    await uploadCSV(csvFile);
  };

  useEffect(() => {
    if (error) {
      setErrors(['server-upload-error']);
    }
  }, [error]);

  const acceptedFileItems = acceptedFiles.map((file) => (
    <div style={{ fontWeight: 'bold' }} key={file.name}>
      {file.name}
    </div>
  ));

  const acceptedFile = acceptedFileItems.length > 0;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 14,
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          paddingTop: 32,
          color: 'rgba(0, 0, 0, 0.87)',
        }}
      >
        CSVデータで実績を登録する
      </div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed rgba(0, 0, 0, 0.12)',
          margin: 32,
          width: 576,
          height: 272,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <input {...getInputProps()} />
          {errors.map((code) => (
            <div key={code} className="mb-3" style={{ color: '#DB2464' }}>
              {FILE_ERRORS.find((c) => c.code === code)?.message ||
                'このファイルは選択できません'}
            </div>
          ))}
          {acceptedFile && !errors.length ? (
            <>
              <p>アップロードするファイル</p>
              <div className="pb-3">{acceptedFileItems}</div>
            </>
          ) : (
            <>
              {isDragActive ? (
                <p>ここにファイルをドロップ</p>
              ) : (
                <p>CSVファイルをドラッグ＆ドロップ</p>
              )}
              <p>または</p>
            </>
          )}

          <Button
            label={
              acceptedFile ? 'ファイルの選択をやり直す' : 'CSVファイルを選択'
            }
            outLine
            onClick={open}
            width={200}
          />
          <p style={{ fontSize: 10, paddingTop: 16 }}>
            ※ アップロード可能なファイルは10,000行までです
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', paddingBottom: 32 }}>
        <div style={{ marginRight: 64 }}>
          <Button label={'キャンセル'} outLine onClick={() => onClickClose()} />
        </div>
        <Button label={'登録する'} onClick={upload} />
      </div>
    </div>
  );
};
