import { FC } from 'react';
import styled from 'styled-components';
import { ListItem, ListItemText, Box } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';

type Props = {
  icon?: JSX.Element;
  drawerOpen: boolean;
  text: string;
  onClick: () => void;
  collapse?: true;
  isCollapseOpen?: boolean;
};

const StyledText = styled.span`
  font-size: 14px;
`;

export const SideMenuListItem: FC<Props> = ({
  icon,
  text,
  drawerOpen,
  onClick,
  collapse,
  isCollapseOpen,
}) => {
  return (
    <ListItem button onClick={onClick}>
      <Box minWidth={40}>{icon}</Box>
      {drawerOpen && (
        <ListItemText
          primary={text}
          primaryTypographyProps={{ component: StyledText }}
        />
      )}
      {collapse &&
        drawerOpen &&
        (isCollapseOpen ? (
          <ExpandLessOutlined fontSize={'small'} />
        ) : (
          <ExpandMoreOutlined fontSize={'small'} />
        ))}
    </ListItem>
  );
};
