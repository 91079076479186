import * as types from '../types/Services/achievement';
import { CLOUD_FUNCTION_URL, API_PATH } from '../containers/api';
import firebase from 'firebase/app';
import { Fetch, HTTP_METHOD } from '../infra/Fetch';

export const AchievementService = {
  uploadCSV: async (csvString: string): Promise<types.CSVUploadResponse> => {
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('Authentication failed');
      }

      const path = `${CLOUD_FUNCTION_URL}${API_PATH.FILE_UPLOAD}`;
      const res = await Fetch(path, {
        method: HTTP_METHOD.POST,
        headers: {
          'x-api-key': user.uid,
          'content-type': 'text/csv',
        },
        body: csvString,
      });
      return res.json();
    } catch (error) {
      throw new Error(error);
    }
  },
  downloadCSV: async (
    from: string,
    to: string
  ): Promise<types.CSVDownloadResponse> => {
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('Authentication failed');
      }
      const path = `${CLOUD_FUNCTION_URL}${API_PATH.FILE_DOWNLOAD}?fromDate=${from}&toDate=${to}`;
      const res = await Fetch(path, {
        method: HTTP_METHOD.GET,
        headers: { 'x-api-key': user.uid },
      });

      if (!res.ok) {
        const error: { message: string } = await res.json();
        throw new EmptyError(error.message);
      }

      return res.text();
    } catch (error) {
      if (error instanceof EmptyError) {
        throw new Error(error.message);
      }

      throw new Error('CSVダウンロードに失敗しました');
    }
  },
};

class EmptyError extends Error {}
