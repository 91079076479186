import { useEffect, useState } from 'react';
import { RouteMasterDetail } from '../types/RouteMasterList/types';
import { RouteMasterService } from '../services/RouteMasterService';

import { HookResultType } from '../types/hooks';

export const useRouteMasterForm = (
  id: string
): HookResultType<RouteMasterDetail> => {
  const [result, setResult] = useState<HookResultType<RouteMasterDetail>>({
    loading: true,
  });

  useEffect(() => {
    RouteMasterService.findById(id)
      .then((res) =>
        setResult({
          loading: false,
          data: res,
          error: undefined,
        })
      )
      .catch((error) =>
        setResult({
          loading: false,
          error: error,
          data: undefined,
        })
      );
  }, [id]);

  return result;
};
