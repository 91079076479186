export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;

export const Fetch = (path: string, req?: RequestInit) => {
  return fetch(`${path}`, req);
};
