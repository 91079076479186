import React, { FC } from 'react';
import { Box } from '@material-ui/core';

type Props = { value: string; label?: JSX.Element };

export const DataModalTitle: FC<Props> = ({ value, label }) => (
  <Box my={1} display={'flex'}>
    <Box fontSize={20} fontWeight={'fontWeightBold'}>
      {value}
    </Box>
    {label && <Box ml={1}>{label}</Box>}
  </Box>
);
