import { createAction, createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RouteType, SuspensionFlg } from '../constants/RoutList';
export const changeSearch = createAction('ROUTE_LIST/CHANGE_SEARCH');
// export const changeRouteMasters = createAction('ROUTE_LIST/CHANGE_ROUTE_MASTERS')
export const clearRouteMasters = createAction('ROUTE_LIST/CLEAR_ROUTE_MASTERS');
export const updateRouteMaster = createAction('ROUTE_LIST/UPDATE_ROUTE_MASTER');
export const removeRouteMaster = createAction('ROUTE_LIST/REMOVE_ROUTE_MASTER');
export const changeLoadingRatio = createAction<number[] | null>(
  'ROUTE_LIST/CHANGE_LOADING_RATIO'
);
export const changeDisplayWidth = createAction(
  'ROUTE_LIST/CHANGE_DISPLAY_WIDTH'
);
export const changeRouteType = createAction('ROUTE_LIST/CHANGE_ROUTE_TYPE');
export const changeShowDetail = createAction('ROUTE_LIST/CHANGE_SHOW_DETAIL');
export const updateLocationMasters = createAction<any[]>(
  'ROUTE_LIST/UPDATE_LOCATION_MASTERS'
);

const initialState: any = {
  search: '',
  loadingRatios: null,
  // 表示幅
  displayWidth: 60,
  // 拠点間 or 幹線
  routeType: RouteType.KYOTENKAN,
  // 詳細表示 on/off
  isShowDetail: true,
  routePlans: [],
  locationMasters: [],
};

export default createReducer(initialState, {
  [changeSearch.type]: (state, action) => {
    const search = action.payload;
    return {
      ...state,
      search,
    };
  },

  // [changeRouteMasters.type]: (state, action) => {
  //   const routePlans = action.payload
  //   return {
  //     ...state,
  //     routePlans,
  //   }
  // },

  [clearRouteMasters.type]: (state, action) => {
    return {
      ...state,
      routePlans: [],
    };
  },

  [updateRouteMaster.type]: (state, action) => {
    const { routeMasterId, data } = action.payload;
    const { routePlans } = state;
    const index = routePlans.findIndex(
      (routeMaster: any) => routeMasterId === routeMaster.id
    );
    let rp = routePlans[index];

    let newRoutePlans = [];

    if (index === -1) {
      newRoutePlans = routePlans.concat(data);
    } else {
      newRoutePlans = [
        ...routePlans.slice(0, index),
        { ...rp, ...data },
        ...routePlans.slice(index + 1),
      ];
    }

    const OFFSET = 10000;

    const sortedRoutePlans = _.chain(newRoutePlans)
      .filter((routePlan: any) => {
        return (
          _.isNil(routePlan.suspension_flg) ||
          routePlan.suspension_flg === SuspensionFlg.OFF
        );
      })
      .sortBy(newRoutePlans, (routePlan: any) => {
        const departureLocation: any = _.first(routePlan.locations);
        const area = departureLocation ? departureLocation.location.area : 0;
        const lat = departureLocation ? departureLocation.location.lat : 0;
        return area * OFFSET + lat;
      })
      .value();

    return {
      ...state,
      routePlans: sortedRoutePlans,
    };
  },

  [removeRouteMaster.type]: (state, action) => {
    const { routeMasterId } = action.payload;
    const { routePlans } = state;
    return {
      ...state,
      routePlans: routePlans.filter(
        (routePlan: any) => routePlan.id !== routeMasterId
      ),
    };
  },

  [changeLoadingRatio.type]: (state, action) => {
    const loadingRatios = action.payload;
    return {
      ...state,
      loadingRatios,
    };
  },

  [changeDisplayWidth.type]: (state, action) => {
    const displayWidth = action.payload;
    return {
      ...state,
      displayWidth,
    };
  },

  [changeRouteType.type]: (state, action) => {
    const routeType = action.payload;
    return {
      ...state,
      routeType,
    };
  },

  [changeShowDetail.type]: (state, action) => {
    const isShowDetail = action.payload;
    return {
      ...state,
      isShowDetail,
    };
  },

  [updateLocationMasters.type]: (state, action) => {
    const locationMasters = action.payload;
    return {
      ...state,
      locationMasters,
    };
  },
});
