import { VFC } from 'react';

import { ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';

import Logo from '../../assets/img/logo.svg';

type Props = {
  onClick: () => void;
};

const StyledListItemIcon = styled(ListItemIcon)`
  cursor: pointer;
`;

export const SideMenuLogo: VFC<Props> = ({ onClick }) => {
  return (
    <StyledListItemIcon onClick={onClick}>
      <img src={Logo} alt={'Logo'} />
    </StyledListItemIcon>
  );
};
