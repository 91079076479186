import React, { VFC } from 'react';
import styled from 'styled-components';

import { Table, TableBody, TableRow } from '@material-ui/core';

import { BaseMaster } from '../../types/BaseMasterList/types';

import { BaseMasterTableHeader } from './BaseMasterTableHeader';
import { BaseMasterTableCell } from './BaseMasterTableCell';
import { BaseMasterTableCellLayout } from './BaseMasterTableCellLayout';

type Props = {
  baseMasters: BaseMaster[];
  clickRow: (base: BaseMaster) => void;
};
const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const BaseMasterTable: VFC<Props> = ({ baseMasters, clickRow }) => {
  return (
    <Table>
      <BaseMasterTableHeader />
      <TableBody>
        {baseMasters.map((base) => (
          <StyledTableRow onClick={() => clickRow(base)} hover key={base.id}>
            <BaseMasterTableCell>
              <BaseMasterTableCellLayout>
                {base.locationCode}
              </BaseMasterTableCellLayout>
            </BaseMasterTableCell>
            <BaseMasterTableCell>
              <BaseMasterTableCellLayout>{base.name}</BaseMasterTableCellLayout>
            </BaseMasterTableCell>
            <BaseMasterTableCell>{base.area}</BaseMasterTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
