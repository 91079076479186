import React from 'react'
import _ from 'lodash'

import { CONTRACT_TYPES, LOCATION_CARD_HEIGHT, VEHICLE_OWNERS } from '../../../constants/RoutList'
import { formatVehicleClass, getNameByIndex } from '../../../formatter/misc'
import { toTimeFormat } from '../../../formatter/DateTimeFormatter'
import { getOverriddenValue } from '../misc'

interface Props {
  routePlan: any
  style?: any
  onClick: () => void
}

const SERVICE_TAGS = [
  {
    id: 'contract_type',
    width: 16,
    items: CONTRACT_TYPES,
  },
  {
    id: 'vehicle_owner',
    width: 16,
    // items: VEHICLE_OWNERS,
    format: (vehicleOwner: number) => {
      return VEHICLE_OWNERS[vehicleOwner] === '協力会社' ? 'P' : null
    },
  },
  {
    id: 'vehicle_class',
    width: 25,
    format: formatVehicleClass,
  },
]

/**
 * 運行便カード
 */
const RoutePlan = ({ routePlan, style, onClick }: Props) => {
  const departure: any = _.first(routePlan.locations) || {}
  const destination: any = _.last(routePlan.locations) || {}
  const departureLocation: any = departure.location || { name: '' }
  const destinationLocation: any = destination.location || { name: '' }
  const routeMaster: any = routePlan.route_master_ref || { name: '' }
  const tags: any[] = SERVICE_TAGS.map((tag: any) => {
    const value = getOverriddenValue(routePlan, 'route_master_ref', tag.id)
    return {
      id: tag.id,
      shortLabel: tag.format ? tag.format(value) : getNameByIndex(value, tag.items).substr(0, 1),
      fullLabel: tag.format ? tag.format(value) : getNameByIndex(value, tag.items),
      width: tag.width,
    }
  }).filter((tag: any) => Boolean(tag.shortLabel))
  console.log(`departureLocation`);
  console.log(departureLocation);
  return (
    <div
      className="rounded"
      style={{
        width: 104,
        height: LOCATION_CARD_HEIGHT,
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.38)',
        ...style,
      }}
      onClick={onClick}
    >
      <div className="d-flex justify-content-center">
        <div className="mr-1" style={{ marginTop: 2 }}>
          <div className="text-center" style={{ fontSize: 14 }} title={departureLocation.abbreviation}>
            {departureLocation.abbreviation.substr(0, 2)}
          </div>
          <div className="text-center" style={{ fontSize: 12 }}>
            {toTimeFormat(departure.departure_time_seconds)}
          </div>
        </div>

        <div className="mr-1 d-flex flex-column justify-content-center">
          <i className="fas fa-arrow-right" style={{ fontSize: 10 }} />
        </div>

        <div style={{ marginTop: 2 }}>
          <div className="text-center" style={{ fontSize: 14 }} title={destinationLocation.abbreviation}>
            {destinationLocation.abbreviation.substr(0, 2)}
          </div>
          <div className="text-center" style={{ fontSize: 12 }}>
            {toTimeFormat(destination.destination_time_seconds)}
          </div>
        </div>
      </div>

      <div
        className="text-center"
        style={{ fontSize: 12, marginBottom: 2 }}
        title={routeMaster.name}
      >
        {routeMaster.name}
      </div>

      {/* tags */}
      <div className="d-flex justify-content-center pb-1">
        {tags.map((tag) => (
          <div
            key={tag.id}
            className="mr-1 text-center"
            title={tag.fullLabel}
            style={{
              width: tag.width,
              height: 16,
              borderRadius: '50%',
              backgroundColor: 'white',
              fontSize: 10,
              padding: 2,
              lineHeight: '12px',
              overflow: 'hidden',
            }}
          >
            {tag.shortLabel}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RoutePlan
