import { VFC } from 'react';

import styled from 'styled-components';
import { ListItem } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

type Props = {
  handleDrawer: () => void;
};
const StyledListItem = styled(ListItem)`
  width: 56px;
  margin-right: 20px;
`;

export const SideMenuToggle: VFC<Props> = ({ handleDrawer }) => {
  return (
    <StyledListItem button>
      <MenuRounded onClick={handleDrawer} />
    </StyledListItem>
  );
};
