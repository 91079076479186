import React, { VFC } from 'react';
import moment from 'moment';

import BlueGauge from './BlueGauge';
import { toTimeFormat } from '../../../formatter/DateTimeFormatter';
import {
  ACHIEVEMENT_NOT_ENTERED,
  FAR_LEFT_TIME,
  LOCATION_CARD_HEIGHT,
  SECONDS_OF_MINUTE,
  WIDTH_OF_1HOUR,
} from '../../../constants/RoutList';

interface Props {
  location: any;
  nextLocation?: any;
  displayWidth: number;
  style?: any;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const MINUTE_TO_WIDTH_FACTOR = WIDTH_OF_1HOUR / 60;
const SMALL_MAX_WIDTH = 30;
const MEDIUM_MAX_WIDTH = 78;
const OFFSET = -FAR_LEFT_TIME * 60 + 30;

const Location: VFC<Props> = ({
  location,
  nextLocation,
  displayWidth,
  style,
  onClick,
}) => {
  const { departure_time_seconds, destination_time_seconds } = location;

  const loading = {
    plan: location.loading_plan,
    achievement: location.loading_achievement,
  };
  const unloading = {
    plan: location.unloading_plan,
    achievement: location.unloading_achievement,
  };

  const loc = location.location || {};
  // 出発地と到着地は強制的に1時間
  const minutes =
    (departure_time_seconds - destination_time_seconds) / SECONDS_OF_MINUTE;

  const left =
    timeToLeft(destination_time_seconds) *
    MINUTE_TO_WIDTH_FACTOR *
    (60 / displayWidth);
  const nextLocationLeft = nextLocation
    ? timeToLeft(nextLocation.destination_time_seconds) *
      MINUTE_TO_WIDTH_FACTOR *
      (60 / displayWidth)
    : 0;

  const width = minutes * MINUTE_TO_WIDTH_FACTOR * (60 / displayWidth);

  const lineWidth = nextLocationLeft ? nextLocationLeft - left - 0 : width;
  const size = determineSize(width);

  const startRate =
    location.achievement_flg === ACHIEVEMENT_NOT_ENTERED
      ? location.destination_capacity_plan
      : location.destination_capacity;
  const leftRate =
    location.achievement_flg === ACHIEVEMENT_NOT_ENTERED
      ? location.destination_capacity_plan - location.unloading_plan
      : location.destination_capacity - location.unloading_achievement;
  const rightRate =
    location.achievement_flg === ACHIEVEMENT_NOT_ENTERED
      ? location.departure_capacity_plan
      : location.capacity;
  const lineStyle =
    location.achievement_flg === ACHIEVEMENT_NOT_ENTERED ? 'dashed' : 'solid';

  return (
    <div
      className="p-1 bg-white position-absolute rounded clickable"
      style={{
        width,
        height: LOCATION_CARD_HEIGHT,
        top: 8,
        left,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
        ...style,
      }}
      onClick={onClick}
    >
      <div
        className="text-center position-relative text-truncate"
        style={{ fontSize: 14, zIndex: 2 }}
        title={loc.abbreviation}
      >
        {loc.abbreviation}
      </div>

      {/* {size === 'medium' && <Medium departure={departure} destination={destination} />} */}

      {size === 'large' && (
        <Large loading={loading} unloading={unloading} width={width} />
      )}

      <BlueGauge
        startRate={startRate}
        leftRate={leftRate}
        rightRate={rightRate}
        leftLineStyle={lineStyle}
        rightLineStyle={lineStyle}
        width={lineWidth}
        locationWidth={width}
        style={{
          position: 'absolute',
          top: 0,
          left: -1,
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default Location;

export const timeToLeft = (seconds: number) => {
  const time = toTimeFormat(seconds);
  const [hours, minutes] = time.split(':');
  const nHours: number = Number(hours);
  const nMinutes: number = Number(minutes);
  const afterMidnightMinutes =
    moment.unix(seconds).toDate().getHours() < FAR_LEFT_TIME ? 24 * 60 : 0;
  return 60 * nHours + nMinutes + afterMidnightMinutes + OFFSET;
};

const determineSize = (width: number) => {
  if (width < SMALL_MAX_WIDTH) {
    return 'small';
  } else if (width < MEDIUM_MAX_WIDTH) {
    return 'medium';
  }

  return 'large';
};

const Panel = ({ data, width, arrowDirection }: any) => (
  <div className="text-center" style={{ width }}>
    <div style={{ marginBottom: 6 }}>
      <i
        className={`fas fa-arrow-${arrowDirection}`}
        style={{ fontSize: 10, marginRight: 2 }}
      />
      <span style={{ fontSize: 14 }}>{data.achievement}</span>
    </div>
    <div style={{ fontSize: 10 }}>(計{data.plan})</div>
  </div>
);

const Large = ({ loading, unloading, width }: any) => (
  <div
    className="d-flex justify-content-around position-relative"
    style={{ zIndex: 2 }}
  >
    <Panel data={unloading} width={width / 2 - 1} arrowDirection="down" />

    <div
      style={{
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(0, 0, 0, 0.12)',
        borderLeftStyle: 'solid',
        width: 2,
        height: 36,
        marginTop: 6,
      }}
    />

    <Panel data={loading} width={width / 2 - 1} arrowDirection="up" />
  </div>
);
