export const FILE_ERRORS = [
  {
    code: 'file-invalid-type',
    message: 'CSVファイル以外は選択できません',
  },
  {
    code: 'too-many-files',
    message: '選択できるファイルは１件です',
  },
  {
    code: 'empty-file',
    message: 'ファイルが未選択です',
  },
  {
    code: 'server-upload-error',
    message: 'ファイルのアップロードに失敗しました',
  },
];
