import React, { FC } from 'react';
import { Box } from '@material-ui/core';

type Props = {
  bold?: boolean;
  label: JSX.Element | string;
};

export const DataModalListItemLabel: FC<Props> = ({ bold, label }) => (
  <Box
    color={!bold ? 'gray' : '#000000'}
    fontWeight={!bold ? 'fontWeightRegular' : 'fontWeightBold'}
    fontSize={14}
  >
    {label}
  </Box>
);
