// override された値を優先して取得、なければマスターの値を取得
export const getOverriddenValue = (data: any, masterPropName: string, key: string) => {
  const value = data[key]

  if (data[masterPropName]) {
    return value || data[masterPropName][key]
  }

  return value
}
