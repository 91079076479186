import React from 'react';

import {
  AssignmentOutlined,
  BusinessOutlined,
  SettingsOutlined,
} from '@material-ui/icons';

import { useSideMenu } from '../hooks/useSideMenu';
import { SideMenu } from '../components/SideMenu/SideMenu';
import { SideMenuHeaderLayout } from '../components/SideMenu/SideMenuHeaderLayout';
import { SideMenuToggle } from '../components/SideMenu/SideMenuToggle';
import { SideMenuLogo } from '../components/SideMenu/SideMenuLogo';
import { SideMenuListLayout } from '../components/SideMenu/SideMenuListLayout';
import { SideMenuListItem } from '../components/SideMenu/SideMenuListItem';
import { SideMenuCollapse } from '../components/SideMenu/SideMenuCollapse';
import { LogoutIcon } from '../components/SideMenu/LogoutIcon';

export const SideMenuContainer = () => {
  const {
    drawerOpen,
    masterListOpen,
    handleDrawer,
    handleMasterList,
    handleRouteChange,
    handleLogout,
  } = useSideMenu();

  return (
    <SideMenu drawerOpen={drawerOpen}>
      <SideMenuHeaderLayout>
        <SideMenuToggle handleDrawer={handleDrawer} />
        {/* TODO: userが設定した初期画面に遷移させる */}
        <SideMenuLogo onClick={() => handleRouteChange('/beta/route-list')} />
      </SideMenuHeaderLayout>
      <SideMenuListLayout>
        <SideMenuListItem
          text={'運行一覧表'}
          icon={<AssignmentOutlined />}
          drawerOpen={drawerOpen}
          onClick={() => handleRouteChange('/beta/route-list')}
        />
        <SideMenuListItem
          text={'拠点View'}
          icon={<BusinessOutlined />}
          drawerOpen={drawerOpen}
          onClick={() => handleRouteChange('/beta/baseView')}
        />
        <SideMenuListItem
          text={'マスタ管理'}
          icon={<AssignmentOutlined />}
          drawerOpen={drawerOpen}
          onClick={handleMasterList}
          collapse
          isCollapseOpen={masterListOpen}
        />
        <SideMenuCollapse isOpen={masterListOpen}>
          <SideMenuListItem
            text={'運行マスタ'}
            drawerOpen={drawerOpen}
            onClick={() => (window.location.href = '/routeMasterList')}
            // TODO: React画面反映時に変更
            // onClick={() => handleRouteChange('/beta/route-master-list')}
          />
          <SideMenuListItem
            text={'拠点マスタ'}
            drawerOpen={drawerOpen}
            // TODO: React画面対応後handleRouteChangeに変更
            onClick={() => (window.location.href = '/masterList')}
          />
        </SideMenuCollapse>
        <SideMenuListItem
          text={'設定'}
          icon={<SettingsOutlined />}
          drawerOpen={drawerOpen}
          // TODO: React画面対応後handleRouteChangeに変更
          onClick={() => (window.location.href = '/setting')}
        />
        <SideMenuListItem
          text={'ログアウト'}
          icon={<LogoutIcon />}
          drawerOpen={drawerOpen}
          onClick={handleLogout}
        />
      </SideMenuListLayout>
    </SideMenu>
  );
};
