import React, { useEffect, useState, VFC } from 'react';
import DayPicker, { DateUtils, Modifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Modifier } from 'react-day-picker/types/Modifiers';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ja';
import { DateRangePickerWeekDay } from './DateRangePickerWeekDay';
import { DAY_COLORS } from '../constants/shared';
import { DateRangePickerCaption } from './DateRangePickerCaption';

type State = Partial<{
  from: Date;
  to: Date;
  enteredTo: Date;
}>;

type Props = {
  initialMonth?: Date;
  fromMonth?: Date;
  onChange: (from: Date, to: Date) => void;
};

const initialState: State = {
  from: undefined,
  to: undefined,
  enteredTo: undefined,
};

/**
 * @see http://react-day-picker.js.org/examples/selected-range-enter
 */
export const DateRangePicker: VFC<Props> = ({
  onChange,
  initialMonth = new Date(),
  fromMonth,
}) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    const { from, to } = state;
    if (from && to) {
      onChange(from, to);
    }
  }, [onChange, state]);

  const isSelectingFirstDay = (
    from: Date | undefined,
    to: Date | undefined,
    day: Date
  ): boolean | undefined | Date => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (date: Date): void => {
    const { from, to } = state;
    if (from && to && date >= from && date <= to) {
      setState(initialState);
      return;
    }
    if (isSelectingFirstDay(from, to, date)) {
      setState({
        from: date,
        to: undefined,
        enteredTo: undefined,
      });
      return;
    }
    setState({
      from,
      to: date,
      enteredTo: date,
    });
  };

  const handleDayMouseEnter = (date: Date): void => {
    const { from, to } = state;
    if (!isSelectingFirstDay(from, to, date)) {
      setState({
        from,
        to,
        enteredTo: date,
      });
    }
  };

  // TODO 型
  const modifiers = {
    from: state.from,
    to: state.to,
    saturday: { daysOfWeek: [6] },
    sunday: { daysOfWeek: [0] },
  } as Partial<Modifiers>;

  const selectedDays = [
    state.from,
    { from: state.from, to: state.enteredTo },
  ] as Modifier[];
  const disabledDays = { before: state.from } as Modifier;

  // 土日に色をつける為、スタイルをカスタマイズする
  // disabled時に土日の色が上書きされないよう制御
  const customStyle = `
  .DayPicker-Week .DayPicker-Day--saturday {
    color: ${DAY_COLORS.SATURDAY}
  }
  .DayPicker-Week .DayPicker-Day--sunday {
    background-color: #fff;
    color: ${DAY_COLORS.SUNDAY}
  }
  .DayPicker-Week .DayPicker-Day--disabled {
    color: #DCE0E0;
  }
  .DayPicker-Day--today {
    color: #000;
    text-decoration: underline
  }
 `;
  return (
    <>
      <style>{customStyle}</style>
      <div style={{ height: 339 }}>
        <DayPicker
          tabIndex={-1}
          initialMonth={initialMonth}
          fromMonth={fromMonth}
          numberOfMonths={2}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          modifiers={modifiers}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          localeUtils={MomentLocaleUtils}
          locale={'ja'}
          weekdayElement={({ locale, localeUtils, className, weekday }) => (
            <DateRangePickerWeekDay
              locale={locale}
              localeUtils={localeUtils}
              className={className}
              weekday={weekday}
            />
          )}
          captionElement={({ date }) => <DateRangePickerCaption date={date} />}
        />
      </div>
    </>
  );
};
