import _ from 'lodash';

import {
  VEHICLE_CLASSES,
  SHARE_SCOPES,
  ROUTE_TYPES,
  LOCATION_TYPES,
  CONTRACT_TYPES,
} from '../constants/RoutList';

export const formatVehicleClass = (index: number) => {
  /** データが正しくない場合の応急処置*/
  if (isNaN(index)) {
    return ''
  }
  // console.assert(0 <= index && index < CAPACITIES.length)
  return _.isNil(index) ? '' : `${VEHICLE_CLASSES[index]}`;
};

export const formatShareScope = (index: number) => {
  // console.assert(0 <= index && index < SHARE_SCOPES.length)
  return _.isNil(index) ? '' : SHARE_SCOPES[index];
};

export const formatRouteType = (index: number) => {
  // console.assert(0 <= index && index < ROUTE_TYPES.length)
  return _.isNil(index) ? '' : ROUTE_TYPES[index];
};

export const formatLocationType = (index: number) => {
  // console.assert(0 <= index && index < LOCATION_TYPES.length)
  return _.isNil(index) ? '' : LOCATION_TYPES[index];
};

export const formatContractType = (index: number) => {
  // console.assert(0 <= index && index < ROUTE_TYPES.length)
  return _.isNil(index) ? '' : CONTRACT_TYPES[index];
};

export const getNameByIndex = (index: number, items: any[]) => {
  /** データが正しくない場合の応急処置*/
  const result = _.isNil(index) ? '' : items[index];
  return result ? result : ''
};

export const removeHeadZero = (target: string) => {
  return target.replace(/^0/, '');
};
