import { FC } from 'react';

import { List, Collapse } from '@material-ui/core';

type Props = {
  isOpen: boolean;
};

export const SideMenuCollapse: FC<Props> = ({ children, isOpen }) => {
  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children}
      </List>
    </Collapse>
  );
};
