const rows = [
  {
    label: 'バレット(膝)',
    category: 'category1',
    item: 'item1',
  },
  {
    label: 'バレット(腰)',
    category: 'category1',
    item: 'item2',
  },
  {
    label: 'バレット(肩)',
    category: 'category1',
    item: 'item3',
  },
  {
    label: 'バレット(頭)',
    category: 'category1',
    item: 'item4',
  },
  {
    label: 'マシン-中型(1段)',
    category: 'category2',
    item: 'item5',
  },
  {
    label: 'マシン-中型(2段)',
    category: 'category2',
    item: 'item6',
  },
  {
    label: 'マシン-大型(大型)',
    category: 'category2',
    item: 'item7',
  },
]

interface Props {
  values: any
  className?: string
}

const Achievement = ({ values, className }: Props) => {
  return (
    <div className={className}>
      {rows.map((row) => (
        <div key={row.label} className="d-flex align-items-center mb-2">
          <div className="mr-2 color-gray60 fs-12" style={{ width: 240 }}>
            {row.label}
          </div>
          <div className="text-right fs-14" style={{ width: 60 }}>
            {extractValue(values, row.category, row.item)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Achievement

const extractValue = (values: any, categoryKey: string, itemKey: string) => {
  const category: any = values?.[categoryKey] || {}
  return category[itemKey]
}
