import { Component } from 'react'
import { connect } from 'react-redux'

interface Props {}

// 荷主一覧
class ShipperListPage extends Component<Props> {
  componentDidMount() {
    // TODO fetch cargo owners
  }

  onClickShipper = () => {}

  render() {
    return (
      <div>
        <button onClick={this.onClickShipper}>open modal</button>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ShipperListPage)
