import React from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
// import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
// import { createFirestoreInstance } from 'redux-firestore'

import './App.css';
import { store } from './store';
import Base from './Base';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

try {
  firebase.app();
} catch (error) {
  firebase.initializeApp(firebaseConfig);
}

// const rrfConfig = {
//   // userProfile: 'users'
//   // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
// }

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance,
// }

const App = () => (
  <Provider store={store}>
    <Base />
  </Provider>
);

// const App = () => (
//   <Provider store={store}>
//     <ReactReduxFirebaseProvider {...rrfProps}>
//       <BrowserRouter>
//         <div onError={onError}>
//           <Switch>
//             <Route path="/" exact component={LoginPage} />
//             <Route path="/login" component={LoginPage} />
//             <Route path="/route-list" component={RouteListPage} />
//           </Switch>
//         </div>
//       </BrowserRouter>
//     </ReactReduxFirebaseProvider>
//   </Provider>
// )

export default App;
