import moment from 'moment';
import {
  CSV_NAME_FORMAT,
  CSV_NAME_PREFIX,
} from '../../constants/AchievementDownload';
import { DateRange } from '../../types/AchievementDownload/types';

export const createCSVFileName = (from: Date, to: Date): string => {
  if (moment(from).isSame(to, 'day')) {
    return `${CSV_NAME_PREFIX}${moment(from).format(CSV_NAME_FORMAT)}.csv`;
  }
  return `${CSV_NAME_PREFIX}${moment(from).format(CSV_NAME_FORMAT)}_${moment(
    to
  ).format(CSV_NAME_FORMAT)}.csv`;
};

export const formatRangeFieldValue = ([from, to]: DateRange): string => {
  const format = 'YYYY年MM月DD日（dd）';
  if (!from || !to) {
    return '';
  }
  return `${moment(from).format(format)} 〜 ${moment(to).format(format)}`;
};
