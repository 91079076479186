import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const StyledErrorOutline = styled(ErrorOutline)`
  font-size: 55px;
  color: rgba(0, 0, 0, 0.38);
`;
export const DataModalError: FC<{ message: string }> = ({
  message,
  children,
}) => {
  return (
    <Box textAlign={'center'} p={3}>
      <StyledErrorOutline />
      <Box fontSize={13} color={' rgba(0, 0, 0, 0.6)'} pt={3}>
        情報の{message}に失敗しました
        <br />
        株式会社モノフル 垣見（080-9367-3926）までご連絡ください
      </Box>
      {children}
    </Box>
  );
};
