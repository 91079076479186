import { VFC } from 'react';
import { CSVUploadResponse } from '../../types/Services/achievement';
import { Button } from '../shared/Button';

interface Props {
  data: CSVUploadResponse | undefined;
  onClickClose: () => void;
}

export const AchievementUploadResult: VFC<Props> = ({
  data,
  onClickClose,
}: Props) => {
  const errors = data!.errors;
  const success = data!.success;
  return (
    <div
      style={{
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
      }}
    >
      {success > 0 && (
        <div
          style={{
            paddingTop: 16,
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          実績の登録が完了しました
        </div>
      )}

      {errors.length > 0 && (
        <div style={{ color: '#DB2464', paddingTop: 32 }}>
          <div>以下の便名の登録に失敗しました</div>
          {errors.map((error, idx) => (
            <div key={idx}>
              ・{error.routeName}：{error.error}
            </div>
          ))}
        </div>
      )}
      <div style={{ marginTop: 32 }}>
        <Button label={'閉じる'} onClick={() => onClickClose()}></Button>
      </div>
    </div>
  );
};
