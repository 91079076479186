import { VFC } from 'react';
import { AchievementUpload } from '../AchievementUpload/AchievementUpload';

interface Props {
  onClickClose: () => void;
}

export const AchievementUploadModal: VFC<Props> = ({ onClickClose }: Props) => {
  return (
    <>
      <AchievementUpload onClickClose={onClickClose} />
    </>
  );
};
