export const FIRESTORE_COLLECTION_KEY = {
  CLIENT: 'client',
  ROUTE_MASTER: 'routeMaster',
  ROUTE_PLAN: 'routePlan',
  LOCATION: 'location',
};

export const DELETE_FLG = {
  NOT_DELETED: 0,
  DELETED: 1,
} as const;
