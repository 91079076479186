import React, { VFC } from 'react';
import { TableCell, Box } from '@material-ui/core';

import { VehicleClass } from '../../types/shared';

export const RouteMasterTableVehicleClassCell: VFC<{
  vehicleClass: VehicleClass;
}> = ({ vehicleClass }) => (
  <TableCell>
    <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
      <Box width={80} mr={1}>
        {vehicleClass}
      </Box>
    </Box>
  </TableCell>
);
