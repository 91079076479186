import { useEffect, useState } from 'react';
import { RouteMaster } from '../types/RouteMasterList/types';
import { RouteMasterService } from '../services/RouteMasterService';

export const useRouteMasters = (): {
  routeMasters: RouteMaster[] | undefined;
  error: Error | undefined;
  loading: boolean;
} => {
  const [routeMasters, setRouteMasters] = useState<RouteMaster[] | undefined>(
    undefined
  );
  const [error, setError] = useState<Error | undefined>(undefined);
  useEffect(() => {
    RouteMasterService.findAll()
      .then((res) => setRouteMasters(res))
      .catch((error) => setError(error));
  }, []);

  return {
    routeMasters,
    error,
    loading: !routeMasters && !error,
  };
};
