import { FC } from 'react';

import styled, { css } from 'styled-components';
import { Drawer, Box } from '@material-ui/core';

type Props = {
  drawerOpen: boolean;
};

const OpenDrawerWidth = '170px';
const BaseDrawerStyle = css`
  min-height: 100vh;
  background-color: #132d49;
  position: unset;
  color: #fff;
`;
const StyledDrawerOpen = styled.div`
  width: ${OpenDrawerWidth};
  ${BaseDrawerStyle};
`;
const StyledDrawerClose = styled.div`
  width: 56px;
  ${BaseDrawerStyle};
`;

export const SideMenu: FC<Props> = ({ children, drawerOpen }) => {
  return (
    <Drawer
      variant={'permanent'}
      PaperProps={{
        component: drawerOpen ? StyledDrawerOpen : StyledDrawerClose,
      }}
    >
      <Box position={'fixed'} width={'inherit'}>
        {children}
      </Box>
    </Drawer>
  );
};
