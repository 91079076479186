import React, { VFC } from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';
import styled from 'styled-components';

const StyledTableRow = styled(TableRow)`
  white-space: nowrap;
`;

export const BaseMasterTableHeader: VFC = () => (
  <TableHead>
    <StyledTableRow>
      <TableCell align={'left'} width={400}>
        拠点コード
      </TableCell>
      <TableCell align={'left'} width={480}>
        拠点名称
      </TableCell>
      <TableCell align={'left'}>地域</TableCell>
    </StyledTableRow>
  </TableHead>
);
