import firebase from 'firebase/app';
import 'firebase/firestore';
import _ from 'lodash';

import { RouteType, SuspensionFlg } from '../../constants/RoutList';

/**
 * 運行便一覧を取得
 */
export const fetchRoutePlans = async (
  uid: string,
  fromDate: Date,
  toDate: Date,
  routeType: RouteType,
  departureLocationName: string,
  shipperName: string,
  displayCount: number
) => {
  const ref = await getClientRef(uid);
  const collectionRef = ref
    .collection('routePlan')
    .where('departure_time', '>=', fromDate)
    .where('departure_time', '<', toDate)
    .where('type', '==', routeType)
    .orderBy('departure_time', 'asc')
    .orderBy('departure_location_area', 'asc')
    .orderBy('departure_location_lat', 'asc');

  if (departureLocationName && shipperName) {
    return collectionRef
      .where('departure_location_name', '==', departureLocationName)
      .where('shipper_name', '==', shipperName);
  } else if (departureLocationName) {
    return collectionRef.where(
      'departure_location_name',
      '==',
      departureLocationName
    );
  } else if (shipperName) {
    return collectionRef.where('shipper_name', '==', shipperName);
  }

  return collectionRef;
};

export const fetchLocationMasters = async (uid: string) => {
  const ref = await getClientRef(uid);
  return ref.collection('location').where('delete_flg', '==', 0);
};

export const suspendRoutePlan = async (uid: string, routePlanId: string) => {
  const ref = await getClientRef(uid);
  ref.collection('routePlan').doc(routePlanId).set(
    {
      suspension_flg: SuspensionFlg.ON,
    },
    { merge: true }
  );
};

export const getClientRef = async (uid: string) => {
  const db = firebase.firestore();
  const clientRef = await db
    .collection('client')
    .where('userIds', 'array-contains', uid)
    .get();
  const doc: any = _.first(clientRef.docs);
  return doc.ref;
};
