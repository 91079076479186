import moment, { Moment } from 'moment';
import { FAR_LEFT_TIME, HOURS_OF_DAY } from '../constants/RoutList';
import { TimeString, UnixTimestamp } from '../pages/RouteList/types';

export const convertTimeToDate = (time: string) => {
  console.assert(time && time.includes(':'));

  const [hours, minutes] = time.split(':') as string[];
  const nHours = Number(hours);
  const nMinutes = Number(minutes);
  const departureTime = moment();

  if (nHours < FAR_LEFT_TIME) {
    departureTime.add(1, 'days');
  }

  departureTime.hours(nHours);
  departureTime.minutes(nMinutes);
  departureTime.seconds(0);
  return departureTime.toDate();
};

export const findConflictedLocation = (
  locations: any[],
  time: Moment | TimeString
): any | undefined => {
  if (typeof time === 'string') {
    return locations.find((location: any) => {
      const { destination_time_seconds, departure_time_seconds } = location;
      return (
        moment.unix(destination_time_seconds).format('HH:mm') <= time &&
        time < moment.unix(departure_time_seconds).format('HH:mm')
      );
    });
  }
  return locations.find((location) => {
    const { destination_time_seconds, departure_time_seconds } = location;
    return (
      time.isSameOrAfter(moment.unix(destination_time_seconds)) &&
      time.isSameOrBefore(moment.unix(departure_time_seconds))
    );
  });
};

// fromTime と toTime の間の時間帯に拠点があるか判定する
export const isIncludesLocation = (
  fromTime: TimeString | Moment,
  toTime: TimeString | Moment,
  locations: any[]
): boolean => {
  if (typeof fromTime === 'string' || typeof toTime === 'string') {
    return locations.some((location: any) => {
      const { destination_time_seconds, departure_time_seconds } = location;
      return (
        fromTime <= moment.unix(destination_time_seconds).format('HH:mm') &&
        moment.unix(departure_time_seconds).format('HH:mm') < toTime
      );
    });
  }
  return locations.some((location: any) => {
    const { destination_time_seconds, departure_time_seconds } = location;
    return (
      moment.unix(destination_time_seconds).isSameOrAfter(fromTime) &&
      moment.unix(departure_time_seconds).isSameOrBefore(toTime)
    );
  });
};

// 24時間表記に変換します
export const convertTo24Hours = (hour: number): number =>
  hour >= 24 ? hour - 24 : hour;

// 選択可能な到着時刻を取得する
export const determineDestinationTime = (
  locations: any[],
  time: TimeString
): TimeString => {
  console.assert(time.length === 5);

  const location: any = findConflictedLocation(locations, time);
  return location
    ? moment.unix(location.departure_time_seconds).format('HH:mm')
    : time;
};

// 選択可能な出発時刻を取得する
export const determineDepartureTime = (
  locations: any[],
  time: TimeString
): TimeString => {
  console.assert(time.length === 5);

  const location: any = findConflictedLocation(locations, time);
  return location
    ? moment.unix(location.destination_time_seconds).format('HH:mm')
    : time;
};

// 深夜1時が25時になる表記に変換する
export const getHoursBy30HourClock = (time: UnixTimestamp) => {
  const hours = moment.unix(time).toDate().getHours();
  return hours < FAR_LEFT_TIME ? hours + HOURS_OF_DAY : hours;
};

/** 日付変更時間を04:00とする現在の日付を返します */
export const currentDate = (): number => {
  const result = moment();
  if (result.hour() >= 0 && result.hour() < 4) {
    result.add(-1, 'day');
  }
  return result.toDate().getDate();
};
