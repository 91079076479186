import { VFC } from 'react';

import { useAchievementUpload } from '../../hooks/Achievement/useAchievementUpload';
import { AchievementUploadLoading } from './AchievementUploadLoading';
import { AchievementUploadFileSelect } from './AchievementUploadFileSelect';
import { AchievementUploadResult } from './AchievementUploadResult';

interface Props {
  onClickClose: () => void;
}

export const AchievementUpload: VFC<Props> = ({ onClickClose }) => {
  const { data, loading, error, uploadCSV } = useAchievementUpload();

  if (loading) {
    return <AchievementUploadLoading />;
  }
  if (data) {
    return <AchievementUploadResult data={data} onClickClose={onClickClose} />;
  }

  return (
    <AchievementUploadFileSelect
      error={error}
      uploadCSV={uploadCSV}
      onClickClose={onClickClose}
    />
  );
};
