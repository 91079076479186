import {
  configureStore,
  // getDefaultMiddleware
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
// import { firebaseReducer } from 'react-redux-firebase'
// import { firestoreReducer } from 'redux-firestore'

import coreReducer from './reducers/core';
import routeListReducer from './reducers/routeList';

const reducer = combineReducers({
  core: coreReducer,
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
  routeList: routeListReducer,
});

export const store = configureStore({
  reducer,
  // middleware: getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: ['@@reactReduxFirebase/LOGIN'],
  //   },
  // }),
});

export const modalSelector = (state: ReturnType<typeof reducer>) =>
  state.core.modal;
