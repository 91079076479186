import React, { VFC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { modalSelector } from '../store';
import { closeModal } from '../reducers/core';
import { useBaseMasterForm } from '../hooks/useBaseMasterForm';
import {
  DataModalActions,
  DataModalContent,
  DataModalHeader,
  DataModalLayout,
  DataModalList,
  DataModalListItem,
  DataModalTitle,
  DataModalLoading,
  DataModalError,
} from '../components/DataModal';

export const BaseMasterDetailContainer: VFC = () => {
  const dispatch = useDispatch();
  const { content } = useSelector(modalSelector);
  const { id } = (content as unknown) as { id: string };
  const results = useBaseMasterForm(id);

  const close = () => {
    dispatch({
      type: closeModal.type,
      payload: {
        type: 'routeMasterDetail',
      },
    });
  };

  return (
    <DataModalLayout>
      <DataModalHeader>
        <DataModalActions
          onCloseClick={close}
          disabled={results.loading || (!results.loading && !!results.error)}
        />
      </DataModalHeader>
      {results.loading && <DataModalLoading message={'情報の取得'} />}
      {!results.loading && results.data && (
        <DataModalContent>
          <DataModalTitle value={results.data.name} />
          <DataModalList>
            <DataModalListItem
              label={'拠点コード'}
              value={results.data.locationCode}
              fullWidth
            />
            <DataModalListItem
              label={'管轄'}
              value={results.data.jurisdiction}
              fullWidth
            />
            <DataModalListItem
              label={'地域'}
              value={results.data.area}
              fullWidth
            />
            <DataModalListItem
              label={'拠点名称'}
              value={results.data.name}
              fullWidth
            />
            <DataModalListItem
              label={'拠点略称'}
              value={results.data.abbreviation}
              fullWidth
            />
            <DataModalListItem
              label={'拠点オペレータ'}
              value={results.data.operator}
              fullWidth
            />
            <DataModalListItem
              label={'拠点種別'}
              value={results.data.locationTypes.join(',')}
              fullWidth
            />
            <DataModalListItem
              label={'拠点住所'}
              value={`〒${results.data.zipCode} ${results.data.address}`}
              fullWidth
            />
            <DataModalListItem
              label={'緯度'}
              value={results.data.lat}
              fullWidth
            />
            <DataModalListItem
              label={'経度'}
              value={results.data.lon}
              fullWidth
            />
            <DataModalListItem
              label={'備考'}
              value={results.data.notes}
              fullWidth
            />
          </DataModalList>
        </DataModalContent>
      )}
      {!results.loading && results.error && <DataModalError message={'取得'} />}
    </DataModalLayout>
  );
};
