import { useCallback, useState } from 'react';

import moment from 'moment';
import { INITIAL_LOCATION_KEY } from '../../constants/BaseView';
import { SearchConditions } from '../../types/BaseView/types';
import { useLocalStorage } from '../useLocalStorage';
import { currentDate } from '../../services/Date';

export const useSearchCondition = () => {
  const initialState: SearchConditions = {
    locationName: '',
    shipperName: '',
    date: moment().set({ date: currentDate() }).toDate(),
    isOnlyNotAchieved: false,
    pressedTime: undefined,
  };

  const { setValue } = useLocalStorage<string>(INITIAL_LOCATION_KEY, '');

  const [conditions, setConditions] = useState<SearchConditions>(initialState);

  const setLocationName = useCallback(
    (locationName: string) => {
      setValue(locationName);
      setConditions({
        ...conditions,
        locationName,
        shipperName: '',
        pressedTime: undefined,
      });
    },
    [conditions, setValue]
  );

  const setShipperName = useCallback(
    (shipperName: string) => {
      setConditions({ ...conditions, shipperName });
    },
    [conditions]
  );

  const setDate = useCallback(
    (date: Date) => {
      setConditions({
        ...conditions,
        date,
        shipperName: '',
        pressedTime: undefined,
      });
    },
    [conditions]
  );

  const setIsOnlyNotAchieved = useCallback(
    (isAchievement: boolean) => {
      setConditions({ ...conditions, isOnlyNotAchieved: isAchievement });
    },
    [conditions]
  );

  const setPressedTime = useCallback(
    (pressedTime: Date | undefined) => {
      setConditions({ ...conditions, pressedTime });
    },
    [conditions]
  );

  return {
    conditions,
    setLocationName,
    setShipperName,
    setDate,
    setIsOnlyNotAchieved,
    setPressedTime,
  };
};
