import { useEffect, useState } from 'react';
import { LOADING_RATIOS, FILTER_CONDITION } from '../constants/RoutList';
import { useDispatch } from 'react-redux';
import { changeLoadingRatio } from '../reducers/routeList';

type FilterType = { rate: number; isActive: boolean };

export const useLoadingRateFilterCondition = (): {
  conditions: FilterType[];
  isSelectedAllCondition: boolean;
  selectAllLoadingRate: () => void;
  selectLoadingRate: (filterButtonStatus: FilterType) => void;
} => {
  const dispatch = useDispatch();
  const [conditions, setConditions] = useState<FilterType[]>(
    LOADING_RATIOS.map((aLoadingRatios) => {
      return {
        rate: aLoadingRatios,
        isActive: FILTER_CONDITION.INACTIVE,
      };
    })
  );

  const [isSelectedAllCondition, setIsSelectedAllCondition] = useState<boolean>(
    FILTER_CONDITION.ACTIVE
  );

  const selectAllLoadingRate = () => {
    setIsSelectedAllCondition(FILTER_CONDITION.ACTIVE);

    const clearFilter = conditions.map((filterStatus) => ({
      ...filterStatus,
      isActive: FILTER_CONDITION.INACTIVE,
    }));
    setConditions(clearFilter);
  };

  const selectLoadingRate = (filterStatus: FilterType) => {
    setIsSelectedAllCondition(FILTER_CONDITION.INACTIVE);
    const changeFilterStatues = conditions.map((selectedRate) =>
      selectedRate.rate === filterStatus.rate
        ? {
            ...filterStatus,
            isActive: !filterStatus.isActive,
          }
        : selectedRate
    );
    setConditions(changeFilterStatues);
  };

  useEffect(() => {
    const isSelectLoadingRate = conditions.some(
      (condition) => condition.isActive === FILTER_CONDITION.ACTIVE
    );
    if (!isSelectLoadingRate) {
      setIsSelectedAllCondition(FILTER_CONDITION.ACTIVE);
    }
    const activeFilters = conditions.filter(
      (rateFilterCondition) =>
        rateFilterCondition.isActive === FILTER_CONDITION.ACTIVE
    );
    const loadingRatios = activeFilters.map((activeFilters) => {
      return activeFilters.rate;
    });

    dispatch(changeLoadingRatio(isSelectedAllCondition ? null : loadingRatios));
  }, [conditions, isSelectedAllCondition, dispatch]);

  return {
    conditions,
    isSelectedAllCondition,
    selectAllLoadingRate,
    selectLoadingRate,
  };
};
