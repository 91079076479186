import { useCallback, useState } from 'react';
import moment from 'moment';
import { AchievementService } from '../../services/AchievementService';
import { convert, stringToCode } from 'encoding-japanese';
import { createCSVFileName } from '../../util/Achievement';

export const useAchievementDownload = () => {
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const format = 'YYYY/MM/DD';
  const downloadCSV = useCallback(async (from: Date, to: Date) => {
    setLoading(true);

    try {
      const fromDate = moment(from).format(format);
      const toDate = moment(to).format(format);

      const res = await AchievementService.downloadCSV(fromDate, toDate);

      const strAry = stringToCode(res as string);
      const converted = convert(strAry, 'SJIS', 'UNICODE');
      const fileName = createCSVFileName(from, to);
      const blob = new Blob([new Uint8Array(converted)], { type: 'text/csv' });

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
      }

      setBlob(blob);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, []);

  return {
    completed: !!(blob && !loading),
    loading,
    error,
    downloadCSV,
  };
};
