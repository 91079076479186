import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storeValue, setStoreValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      const valueToStorage = value instanceof Function ? value(storeValue) : value;
      setStoreValue(valueToStorage);
      window.localStorage.setItem(key, JSON.stringify(valueToStorage));
    },
    [key, storeValue]
  );

  return {
    storeValue,
    setValue,
  };
};
