import { createContext, FC, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';

import { ClientService } from '../services/ClientService';

export const ClientContext = createContext<{ clientId: string | undefined }>({
  clientId: undefined,
});

export const ClientContextProvider: FC = ({ children }) => {
  const [client, setClient] = useState<{ clientId: string | undefined }>({
    clientId: undefined,
  });
  useEffect(() => {
    const user = firebase.auth().currentUser;
    const uid = user?.uid as string;
    ClientService.getClientId(uid).then((clientId) => setClient({ clientId }));
  }, []);
  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
};
