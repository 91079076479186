import React from 'react';
import moment from 'moment';
import { LoadingRateFilter } from './LoadingRateFilter';
import { FilterButton } from './FilterButton';
import { useLoadingRateFilterCondition } from '../../../hooks/useLoadingRateFilterCondition';
import { CloudDownload, CloudUpload } from '@material-ui/icons';
import { withStyles, FormControlLabel, Switch } from '@material-ui/core';

import { currentDate } from '../../../services/Date';

const now = moment().set({ date: currentDate() });

const BlueSwitch = withStyles({
  switchBase: {
    color: '#BDBDBD',
    '&$checked': {
      color: '#247CDB',
    },
    '&$checked + $track': {
      backgroundColor: '#247CDB',
    },
  },
  checked: {},
  track: {},
})(Switch);

const Header = ({
  displayWidth,
  isShowDetail,
  onSubmit,
  onChangeFromDate,
  onChangeShowDetail,
  onChangeDisplayWidth,
  uploadCSV,
  downloadCSV,
}: any) => {
  const {
    conditions,
    isSelectedAllCondition,
    selectAllLoadingRate,
    selectLoadingRate,
  } = useLoadingRateFilterCondition();
  return (
    <div className="pt-2 px-3" style={{ backgroundColor: '#F8F5F1' }}>
      {/* top */}
      <div
        className="pb-2 mb-2"
        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', fontSize: 14 }}
      >
        <button className="mr-3" onClick={uploadCSV}>
          <CloudUpload
            fontSize="small"
            className="mr-2 mb-1"
            style={{ color: 'rgba(0, 0, 0, 0.38)' }}
          />
          <span>実績登録</span>
        </button>
        <button onClick={downloadCSV} className="mr-3">
          <CloudDownload
            fontSize="small"
            className="mr-2 mb-1"
            style={{ color: 'rgba(0, 0, 0, 0.38)' }}
          />
          <span>実績取得</span>
        </button>
        <a
          href="/file_format/実績登録_format.csv"
          style={{ color: 'black' }}
          download="実績登録_format.csv"
        >
          <CloudDownload
            fontSize="small"
            className="mr-2 mb-1"
            style={{ color: 'rgba(0, 0, 0, 0.38)' }}
          />
          <span>登録フォーマット取得</span>
        </a>
      </div>

      {/* bottom */}
      <div className="pb-1" style={{ minWidth: 1026 }}>
        <form action="" onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="拠点検索する"
            size={12}
            className="mr-3"
            style={{ fontSize: 12, padding: '7px 8px' }}
            name="locationName"
          />
          <a
            href="/masterList"
            className="mr-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            拠点マスタ一覧へ
          </a>

          <input
            type="text"
            placeholder="荷主検索する"
            size={12}
            className="mr-3"
            style={{ fontSize: 12, padding: '7px 8px' }}
            name="shipperName"
          />

          <input
            type="date"
            placeholder={now.format('YYYY年MM月DD日')}
            size={12}
            className="from-date mr-3"
            style={{ fontSize: 12, padding: '7px 8px' }}
            defaultValue={now.format('YYYY-MM-DD')}
            required
            onChange={onChangeFromDate}
          />

          <FormControlLabel
            control={
              <BlueSwitch
                checked={isShowDetail}
                onChange={onChangeShowDetail}
                color="primary"
              />
            }
            label={<span style={{ fontSize: 14 }}>詳細表示</span>}
          />
          <select
            onChange={onChangeDisplayWidth}
            className="mr-3"
            style={{ fontSize: 14 }}
            value={displayWidth}
          >
            <option value={15}>15分</option>
            <option value={30}>30分</option>
            <option value={60}>60分</option>
          </select>

          <LoadingRateFilter>
            <FilterButton
              label={'すべて'}
              value={'すべて'}
              className="mr-2 text-center"
              selected={isSelectedAllCondition}
              onClick={() => selectAllLoadingRate()}
            />
            {conditions.map((condition) => {
              return (
                <FilterButton
                  key={condition.rate}
                  value={condition.rate}
                  label={`${condition.rate}%`}
                  className="mr-2 text-center"
                  selected={condition.isActive}
                  onClick={() => selectLoadingRate(condition)}
                />
              );
            })}
          </LoadingRateFilter>

          <button className="d-none" type="submit" />
        </form>
      </div>
    </div>
  );
};

export default Header;
