import 'firebase/auth';
import 'firebase/firestore';

import fb from 'firebase/app';
import moment from 'moment';

import { FIRESTORE_COLLECTION_KEY } from '../constants/Firebase';
import { ACHIEVEMENT_NOT_ENTERED } from '../constants/RoutList';
import { TruckStopInputData } from '../types/RoutePlan/types';
import {
  LocationFields,
  RoutePlanFields,
  RoutePlanLocationFields,
} from '../types/firestore';

export const RoutePlanService = {
  addTruckStop: async ({
    routePlanId,
    inputData,
  }: {
    routePlanId: string;
    inputData: TruckStopInputData;
  }): Promise<void> => {
    const user = fb.auth().currentUser;
    if (!user) {
      throw new Error('Unauthenticated Error');
    }

    const clientSnaps = await fb
      .firestore()
      .collection(FIRESTORE_COLLECTION_KEY.CLIENT)
      .where('userIds', 'array-contains', user.uid)
      .get()
      .then((snap) => snap.docs);

    const [clientSnap] = clientSnaps;
    const clientDocRef = clientSnap.ref;

    const locationRef = clientDocRef
      .collection(FIRESTORE_COLLECTION_KEY.LOCATION)
      .doc(inputData.locationId);

    const locationDoc = await locationRef
      .get()
      .then((snap) => snap.data() as LocationFields);

    const routePlanDocRef = clientDocRef
      .collection(FIRESTORE_COLLECTION_KEY.ROUTE_PLAN)
      .doc(routePlanId);

    const routePlanDoc = await routePlanDocRef
      .get()
      .then((snap) => snap.data() as RoutePlanFields);

    const newLocation: RoutePlanLocationFields = {
      achievement_flg: ACHIEVEMENT_NOT_ENTERED,
      capacity: 0,
      departure_capacity_plan: 0,
      destination_capacity_plan: 0,
      destination_capacity: 0,
      departure_time: fb.firestore.Timestamp.fromDate(inputData.departureTime),
      destination_time: fb.firestore.Timestamp.fromDate(
        inputData.destinationTime
      ),
      location_ref: locationRef,
      location_name: locationDoc.name,
      location_type: locationDoc.locationType,
      loading_plan: inputData.loadingPlan,
      loading_achievement: 0,
      unloading_plan: inputData.unloadingPlan,
      unloading_achievement: 0,
    };

    const newLocations = [...routePlanDoc.locations, newLocation]
      .sort(
        (a, b) =>
          moment(a.departure_time.toDate()).unix() -
          moment(b.departure_time.toDate()).unix()
      )
      .reduce<RoutePlanLocationFields[]>((acc, cur, idx) => {
        if (idx === 0) {
          acc.push(cur);
          return acc;
        }
        const prev = acc[idx - 1];

        cur.destination_capacity_plan = prev.departure_capacity_plan;
        cur.departure_capacity_plan =
          prev.departure_capacity_plan - cur.unloading_plan + cur.loading_plan;

        acc.push(cur);
        return acc;
      }, []);

    await routePlanDocRef.update({
      locations: newLocations,
    });
  },
};
