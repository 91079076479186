import React, { useCallback, useContext, useEffect } from 'react';

import { Box } from '@material-ui/core';

import { Error } from '../components/BaseView/Error';
import { Header } from '../components/BaseView/Header';
import { Table } from '../components/BaseView/Table';
import { INITIAL_LOCATION_KEY } from '../constants/BaseView';
import {
  useLocation,
  useBaseViewLocations,
  useSearchCondition,
} from '../hooks/BaseView';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { ClientContext } from '../contexts/ClientContext';
import { useDispatch } from 'react-redux';
import { openModal } from '../reducers/core';

export const BaseViewContainer = () => {
  const client = useContext(ClientContext);

  // todo カスタムHookに移動
  const dispatch = useDispatch();
  const handleCSVDownload = useCallback(() => {
    dispatch({
      type: openModal.type,
      payload: {
        type: 'achievementDownload',
      },
    });
  }, [dispatch]);

  const { storeValue } = useLocalStorage<string>(INITIAL_LOCATION_KEY, '');
  const {
    conditions,
    setLocationName,
    setDate,
    setShipperName,
    setIsOnlyNotAchieved,
    setPressedTime,
  } = useSearchCondition();

  const {
    data: locations,
    loading: locationLoading,
    error: locationError,
  } = useLocation(client.clientId);
  const {
    data: baseViewLocations,
    loading: baseViewLocationsLoading,
    error: baseViewError,
  } = useBaseViewLocations(conditions, client.clientId);

  useEffect(() => {
    if (!conditions.locationName && locations.length) {
      const initialLocation = storeValue ? storeValue : locations[0].name;
      setLocationName(initialLocation);
    }
  }, [conditions.locationName, locations, setLocationName, storeValue]);

  const isLoading = locationLoading || baseViewLocationsLoading;
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const isError = locationError || baseViewError;

  return (
    <Box width={1} minHeight={'100vh'}>
      <Box px={3}>
        {isError ? (
          <Error />
        ) : (
          <>
            <Header
              conditions={conditions}
              locations={locations}
              setLocationName={setLocationName}
              setDate={setDate}
              setShipperName={setShipperName}
              setIsOnlyNotAchieved={setIsOnlyNotAchieved}
              setPressedTime={setPressedTime}
              downloadCSV={handleCSVDownload}
            />
            <Table
              baseViewLocations={baseViewLocations}
              conditions={conditions}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
