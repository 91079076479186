import { Box } from '@material-ui/core';
import React, { VFC } from 'react';

type Props = {
  bold?: boolean;
  value?: string | number;
};
export const DataModalListItemValue: VFC<Props> = ({ bold, value }) => (
  <Box
    fontSize={14}
    fontWeight={!bold ? 'fontWeightRegular' : 'fontWeightBold'}
  >
    {value}
  </Box>
);
