import React from 'react'

import {
  SECONDS_OF_MINUTE,
  SIMPLE_LOCATION_CARD_HEIGHT,
  WIDTH_OF_1HOUR,
} from '../../../constants/RoutList'
import AchievementLine from './AchievementLine'
import { timeToLeft } from './Location'

const MINUTE_TO_WIDTH_FACTOR = WIDTH_OF_1HOUR / 60

interface Props {
  location: any
  displayWidth: number
  nextLocation?: any
  style?: any
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const LocationSimple = ({ location, displayWidth, nextLocation, style, onClick }: Props) => {
  const { departure_time_seconds, destination_time_seconds } = location
  const left = timeToLeft(destination_time_seconds) * MINUTE_TO_WIDTH_FACTOR * (60 / displayWidth)
  const locationMaster = location.location || {}
  const minutes = (departure_time_seconds - destination_time_seconds) / SECONDS_OF_MINUTE
  const width = minutes * MINUTE_TO_WIDTH_FACTOR * (60 / displayWidth)
  const nextLocationLeft = nextLocation
    ? timeToLeft(nextLocation.destination_time_seconds) *
      MINUTE_TO_WIDTH_FACTOR *
      (60 / displayWidth)
    : 0
  const lineWidth = nextLocationLeft ? nextLocationLeft - left - 0 : width
  const color = determineColor(location.capacity)

  return (
    <div
      className="position-absolute"
      style={{
        width: lineWidth,
        height: 26,
        lineHeight: '26px',
        top: 8,
        left,
        ...style,
      }}
    >
      <div
        title={locationMaster.abbreviation}
        className="rounded text-truncate clickable text-center"
        style={{
          width,
          color: color === 'white' ? 'black' : 'white',
          backgroundColor: color,
          fontSize: 14,
        }}
        onClick={onClick}
      >
        {locationMaster.abbreviation}
      </div>
      <AchievementLine
        width={lineWidth}
        locationWidth={width}
        color={color}
        style={{
          position: 'absolute',
          top: SIMPLE_LOCATION_CARD_HEIGHT / 2 - 1,
          left: 0,
        }}
      />
    </div>
  )
}

export default LocationSimple

const determineColor = (capacity: number) => {
  if (capacity === 0) {
    return 'white'
  } else if (capacity <= 25) {
    return '#DAF5FF'
  } else if (capacity <= 50) {
    return '#A9D0FF'
  } else if (capacity <= 75) {
    return '#007AFF'
  }

  return '#0046C2'
}
