import { useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';

import { DAY_START_HOUR } from '../../constants/BaseView';
import {
  BaseViewLocations,
  SearchConditions,
} from '../../types/BaseView/types';
import { baseViewLocationsConverter } from '../../util/BaseView/BaseViewLocationsConverter';

export const useBaseViewLocations = (
  { locationName, date, shipperName, isOnlyNotAchieved }: SearchConditions,
  clientId?: string
) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [baseViewLocations, setBaseViewLocations] = useState<BaseViewLocations>(
    []
  );

  const { fromDate, toDate } = useMemo(() => {
    const fromDate = moment(date)
      .set('hour', DAY_START_HOUR)
      .set('minute', 0)
      .set('second', 0)
      .toDate();
    const toDate = moment(date)
      .set('hour', DAY_START_HOUR - 1)
      .set('minute', 59)
      .set('second', 59)
      .add(1, 'day')
      .toDate();

    return {
      fromDate,
      toDate,
    };
  }, [date]);

  useEffect(() => {
    if (!clientId) {
      return;
    }
    const unsubscribe = firebase
      .firestore()
      .collection(`client/${clientId}/routePlan`)
      .where('departure_time', '>=', fromDate)
      .where('departure_time', '<', toDate)
      .withConverter(baseViewLocationsConverter)
      .onSnapshot(
        (snapshot) => {
          const routePlans: Array<BaseViewLocations> = [];
          snapshot.forEach((doc) => routePlans.push(doc.data()));

          const baseViewLocations = _.flatten(routePlans);
          setLoading(false);
          setBaseViewLocations(baseViewLocations);
        },
        (error) => {
          console.error(error);
          setLoading(false);
          setError(error);
        }
      );
    return () => unsubscribe();
  }, [fromDate, locationName, toDate, clientId]);

  const searchResult = baseViewLocations
    .filter((location) =>
      locationName ? location.locationsName === locationName : location
    )
    .filter((location) =>
      shipperName ? location.shipperName === shipperName : location
    )
    .filter((location) => (isOnlyNotAchieved ? !location.isAchieved : location))
    .sort((a, b) =>
      moment.unix(a.destinationTime).diff(moment.unix(b.destinationTime))
    );

  return {
    data: searchResult,
    loading,
    error,
  };
};
