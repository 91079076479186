import CircularProgress from '@material-ui/core/CircularProgress'

const Progress = () => (
  <div
    className="text-center"
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 3,
      paddingTop: global.innerHeight / 2 - 20,
    }}
  >
    <CircularProgress />
  </div>
)

export default Progress
