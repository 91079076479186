import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Header from './components/Header';
import Location from './components/Location';
import RoutePlan from './components/RoutePlan';
import TimeRow from './components/TimeRow';
import {
  changeSearch,
  clearRouteMasters,
  updateRouteMaster,
  removeRouteMaster,
  changeLoadingRatio,
  changeDisplayWidth,
  changeRouteType,
  changeShowDetail,
  updateLocationMasters,
} from '../../reducers/routeList';
import {
  openModal,
  closeModal,
  openProgress,
  closeProgress,
  changeWindowSize,
} from '../../reducers/core';
import {
  FAR_LEFT_TIME,
  FAR_RIGHT_TIME,
  HEADER_HEIGHT,
  LEFT_FIXED_AREA_WIDTH,
  LOCATION_CARD_HEIGHT,
  CLOSED_SIDE_MENU_WIDTH,
  OPENED_SIDE_MENU_WIDTH,
  RouteType,
  ROUTE_PLAN_DISPLAY_ITEMS,
  ROUTE_TYPES,
  SIMPLE_LOCATION_CARD_HEIGHT,
  WIDTH_OF_1HOUR,
  ACHIEVEMENT_NOT_ENTERED,
  START_TIME,
  END_TIME,
} from '../../constants/RoutList';
import moment from 'moment';
import { fetchRoutePlans } from './api';
import { getNameByIndex } from '../../formatter/misc';
import LocationSimple from './components/LocationSimple';
import RoutePlanSimple from './components/RoutePlanSimple';
import { User } from './types';
import { RouteMasterLocationFields } from '../../types/firestore';
import {
  determineDepartureTime,
  determineDestinationTime,
  convertTo24Hours,
} from '../../services/Date';
import CurrentTime from './components/CurrentTime';

// 右端にスクロールしたときにずれる対策
const HORIZONTAL_SCROLL_ADJUSTMENT = 33 + 14;
// 下端にスクロールしたときにずれる対策
const VERTICAL_SCROLL_ADJUSTMENT = 10;

interface Props {
  search: string;
  loadingRatios: null | Array<number>;
  displayWidth: number;
  routeType: RouteType;
  isShowDetail: boolean;
  routePlans: any[];
  locationMasters: any[];
  user: User;
  window: {
    innerWidth: number;
    innerHeight: number;
  };
  visibleMenu: boolean;
  changeSearch: (text: string) => void;
  clearRouteMasters: () => void;
  updateRouteMaster: (payload: any) => void;
  removeRouteMaster: (payload: any) => void;
  openModal: (payload: any) => void;
  closeModal: () => void;
  changeLoadingRatio: (loadingRatios: null | Array<number>) => void;
  changeDisplayWidth: (displayWidth: number) => void;
  changeRouteType: (routeType: RouteType) => void;
  changeShowDetail: (isShowDetail: boolean) => void;
  updateLocationMasters: (locations: any[]) => void;
  openProgress: () => void;
  closeProgress: () => void;
  changeWindowSize: ({
    innerWidth,
    innerHeight,
  }: {
    innerWidth: number;
    innerHeight: number;
  }) => void;
}

const canAddLocation = (locations: any[], n: number): boolean => {
  const h = Math.floor(n);
  const m = 60 * (n - h);

  // 出発地
  const departureLocation = _.first(locations) || {};
  // 到着地
  const destinationLocation = _.last(locations) || {};

  const departureTime = moment.unix(departureLocation.departure_time_seconds);
  const destinationTime = moment.unix(
    destinationLocation.destination_time_seconds
  );
  const cellTime = moment
    .unix(departureLocation.departure_time_seconds)
    .set({ h: convertTo24Hours(h), m });

  if (cellTime.isBefore(departureTime)) {
    cellTime.add(1, 'day');
  }

  // 対象のセルが出発時刻以降、到着時刻以前なら立寄地の追加を許可する
  return (
    cellTime.isSameOrAfter(departureTime) && cellTime.isBefore(destinationTime)
  );
};

class RouteListPage extends Component<Props> {
  scrollRef: any;
  scrollRef2: any;
  unsubscribe?: () => void;
  progressTimerId: NodeJS.Timeout | null = null;

  componentDidMount() {
    this.fetch();

    global.window.addEventListener(
      'resize',
      _.throttle(this.onResize, 500, { leading: false })
    );
  }

  onResize = (e: any) => {
    const { innerWidth, innerHeight } = e.target;
    this.props.changeWindowSize({ innerWidth, innerHeight });
  };

  async fetch(aRouteType?: RouteType) {
    const { routeType, user } = this.props;

    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.props.clearRouteMasters();

    const fromDateEl = global.document.querySelector(
      '.from-date'
    ) as HTMLInputElement;

    // 4:00 <= date < 28:00
    const fromDate = moment(
      `${fromDateEl.value} ${START_TIME}`,
      'YYYY-MM-DD HH:mm'
    ).toDate();
    const toDate = moment(`${fromDateEl.value} ${END_TIME}`, 'YYYY-MM-DD HH:mm')
      .add(1, 'day')
      .toDate();

    const locationNameEl: any = global.document.querySelector(
      '[name=locationName]'
    );
    const locationName = locationNameEl.value;
    const shipperNameEl: any = global.document.querySelector(
      '[name=shipperName]'
    );
    const shipperName = shipperNameEl.value;

    this.showProgress();

    const ref = await fetchRoutePlans(
      user.uid,
      fromDate,
      toDate,
      _.isNil(aRouteType) ? routeType : aRouteType,
      locationName,
      shipperName,
      ROUTE_PLAN_DISPLAY_ITEMS
    );
    this.unsubscribe = ref.onSnapshot((snapshot: any) => {
      snapshot.docChanges().forEach((change: any) => {
        const data = change.doc.data();
        const routePlanId = change.doc.id;

        this.showProgress();

        if (change.type === 'added') {
          // console.log('New: ', data)
          convertRoutePlan(routePlanId, data, this.props.updateRouteMaster);
        } else if (change.type === 'modified') {
          // console.log('Modified: ', data)
          convertRoutePlan(routePlanId, data, this.props.updateRouteMaster);
        } else if (change.type === 'removed') {
          // console.log('Removed: ', data)
          this.props.removeRouteMaster({ routeMasterId: routePlanId });
        }
      });
    });
  }

  showProgress() {
    if (this.progressTimerId) {
      clearTimeout(this.progressTimerId);
      this.progressTimerId = null;
    }

    this.props.openProgress();

    this.progressTimerId = setTimeout(() => {
      this.props.closeProgress();
    }, 400);
  }

  onScroll = (e: any) => {
    const { scrollLeft, scrollTop } = e.target;

    this.scrollRef.scrollLeft = scrollLeft;
    this.scrollRef2.scrollTop = scrollTop;
  };

  onChangeSearch = (e: any) => {
    const { value } = e.target;
    this.props.changeSearch(value);
  };

  onSearch = (e: any) => {
    e.preventDefault();
    this.fetch();
  };

  onClickRoute = (routePlan: any) => {
    const { user } = this.props;
    this.props.openModal({
      type: 'route',
      content: { uid: user.uid, routePlan },
    });
  };

  onClickLocation = (routePlan: any, location: any) => {
    this.props.openModal({
      type: 'location',
      content: { routePlan, location },
    });
  };

  onClickLocationAdding = (routePlan: any, n: number, displayWidth: number) => {
    const factor = 60 / displayWidth;
    const hours = convertTo24Hours(FAR_LEFT_TIME + Math.floor(n / factor) - 1);
    const from = {
      hours,
      minutes: displayWidth * (n % factor),
    };
    const m = from.minutes + displayWidth;
    const to = {
      hours:
        m < 60
          ? convertTo24Hours(from.hours)
          : convertTo24Hours(from.hours + 1),
      minutes: m < 60 ? m : m % 60,
    };

    const leftTime = `${from.hours}:${from.minutes || '00'}`.padStart(5, '0');
    const rightTime = `${to.hours}:${to.minutes || '00'}`.padStart(5, '0');

    const fromTime = determineDestinationTime(routePlan.locations, leftTime);
    const toTime = determineDepartureTime(routePlan.locations, rightTime);

    this.props.openModal({
      type: 'locationAddition',
      content: {
        routePlan,
        fromTime,
        toTime,
      },
    });
  };

  onChangeFromDate = () => {
    this.fetch();
  };

  // onChangeToDate = () => {
  //   this.fetch()
  // }

  onChangeDisplayWidth = (e: any) => {
    const { value } = e.target;
    this.props.changeDisplayWidth(Number(value));
  };

  onChangeRouteType = (e: any) => {
    const { value } = e.target;
    const nValue = Number(value);
    this.props.changeRouteType(nValue);
    this.fetch(nValue);
  };

  onChangeShowDetail = (e: any) => {
    const { checked } = e.target;
    this.props.changeShowDetail(checked);
  };

  onUploadCSV = (e: any) => {
    this.props.openModal({ type: 'achievementUpload' });
  };

  onDownloadCSV = (e: any) => {
    this.props.openModal({ type: 'achievementDownload' });
  };

  render() {
    const {
      search,
      loadingRatios,
      displayWidth,
      routeType,
      isShowDetail,
      routePlans,
      window: { innerWidth },
      visibleMenu,
    } = this.props;
    return (
      <div style={{ backgroundColor: '#F8F5F1' }}>
        <div
          className="position-fixed w-100"
          style={{
            top: 0,
            zIndex: 3,
            backgroundColor: '#F8F5F1',
          }}
        >
          <Header
            search={search}
            displayWidth={displayWidth}
            isShowDetail={isShowDetail}
            onChangeSearch={this.onChangeSearch}
            onSubmit={this.onSearch}
            onChangeFromDate={this.onChangeFromDate}
            // onChangeToDate={this.onChangeToDate}
            onChangeDisplayWidth={this.onChangeDisplayWidth}
            onChangeShowDetail={this.onChangeShowDetail}
            uploadCSV={this.onUploadCSV}
            downloadCSV={this.onDownloadCSV}
          />

          <div
            className="d-flex align-items-center pt-2 mx-3"
            style={{
              backgroundColor: '#F8F5F1',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            {[RouteType.KYOTENKAN, RouteType.KANSEN].map((type: number) => (
              <button
                key={type}
                className={`mr-1 pt-1 pb-1`}
                disabled={type === routeType}
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  paddingLeft: 22,
                  paddingRight: 22,
                  color: type === routeType ? '#247CDB' : 'gray',
                  borderBottom:
                    type === routeType ? '2px solid #247CDB' : 'none',
                }}
                value={type}
                onClick={this.onChangeRouteType}
              >
                {getNameByIndex(type, ROUTE_TYPES)}
              </button>
            ))}
          </div>

          <div
            ref={(ref) => {
              this.scrollRef = ref;
            }}
            className="pt-2"
            style={{
              width:
                innerWidth -
                HORIZONTAL_SCROLL_ADJUSTMENT -
                (visibleMenu ? OPENED_SIDE_MENU_WIDTH : CLOSED_SIDE_MENU_WIDTH),
              overflowX: 'hidden',
              paddingLeft: LEFT_FIXED_AREA_WIDTH,
              backgroundColor: '#F8F5F1',
              paddingBottom: 3,
            }}
          >
            <TimeRow
              displayWidth={displayWidth}
              style={{
                width: calcScrollWidth(displayWidth),
              }}
            />
          </div>
        </div>

        <div
          className="pl-3"
          style={{
            backgroundColor: '#F8F5F1',
            width: '100wh',
            marginTop: 170,
          }}
        >
          <div
            className="d-flex"
            style={{
              minHeight: '100vh',
            }}
          >
            {/* 運行基本情報 */}
            <div
              ref={(ref) => {
                this.scrollRef2 = ref;
              }}
              className="d-flex flex-column"
              style={{
                width: 108,
                overflow: 'hidden',
                paddingTop: 5,
              }}
            >
              {routePlans.map((routePlan: any) => (
                <div
                  key={routePlan.id}
                  style={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    height: isShowDetail ? 96 : 42,
                  }}
                >
                  {isShowDetail ? (
                    <RoutePlan
                      routePlan={routePlan}
                      style={{ marginTop: 8, marginBottom: 8 }}
                      onClick={() => this.onClickRoute(routePlan)}
                    />
                  ) : (
                    <RoutePlanSimple
                      key={routePlan.id}
                      routePlan={routePlan}
                      style={{ marginTop: 8, marginBottom: 8 }}
                      onClick={() => this.onClickRoute(routePlan)}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* 拠点 row */}
            <div
              className="d-flex flex-column position-relative"
              style={{
                width:
                  innerWidth -
                  LEFT_FIXED_AREA_WIDTH -
                  (visibleMenu
                    ? OPENED_SIDE_MENU_WIDTH
                    : CLOSED_SIDE_MENU_WIDTH) -
                  7,
                overflowX: 'scroll',
                overflowY: 'auto',
                paddingTop: 5,
              }}
              onScroll={this.onScroll}
            >
              {routePlans.map((routePlan: any) => (
                <div
                  key={routePlan.id}
                  className="d-flex pb-3 position-relative"
                  style={{
                    width: calcScrollWidth(displayWidth),
                    height:
                      (isShowDetail
                        ? LOCATION_CARD_HEIGHT
                        : SIMPLE_LOCATION_CARD_HEIGHT) + 16,
                    zIndex: 1,
                  }}
                >
                  <div className="mr-3">
                    {/* vertical line per hours */}
                    <div
                      className="d-flex position-absolute"
                      style={{
                        top: 0,
                        left: -((WIDTH_OF_1HOUR * (60 / displayWidth)) / 2),
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                    >
                      {_.times(
                        (FAR_RIGHT_TIME - FAR_LEFT_TIME) * (60 / displayWidth),
                        (n: number) => (
                          <div
                            key={n}
                            style={{
                              width: WIDTH_OF_1HOUR,
                              height: isShowDetail ? 96 : 42,
                              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                          >
                            {canAddLocation(
                              routePlan.locations,
                              n / (60 / displayWidth) + FAR_LEFT_TIME - 1
                            ) ? (
                              <button
                                style={{
                                  color: 'rgba(0, 0, 0, 0.24)',
                                  fontSize: 28,
                                  width: WIDTH_OF_1HOUR,
                                  height: '100%',
                                  lineHeight: `${
                                    isShowDetail
                                      ? LOCATION_CARD_HEIGHT
                                      : SIMPLE_LOCATION_CARD_HEIGHT
                                  }px`,
                                }}
                                onClick={() =>
                                  this.onClickLocationAdding(
                                    routePlan,
                                    n,
                                    displayWidth
                                  )
                                }
                              >
                                +
                              </button>
                            ) : null}
                          </div>
                        )
                      )}
                    </div>

                    {Boolean(routePlan.locations) &&
                      routePlan.locations.map((location: any, index: number) =>
                        isShowDetail ? (
                          // 詳細表示 on/off 分岐
                          <Location
                            key={`${location.id}-${location.departure_time_seconds}`}
                            location={location}
                            nextLocation={
                              index < routePlan.locations.length
                                ? routePlan.locations[index + 1]
                                : {}
                            }
                            displayWidth={displayWidth}
                            style={{
                              opacity: isVisibleLocation(
                                loadingRatios,
                                location
                              )
                                ? 1.0
                                : 0.2,
                            }}
                            onClick={() =>
                              this.onClickLocation(routePlan, location)
                            }
                          />
                        ) : (
                          <LocationSimple
                            key={`${location.id}-${location.departure_time_seconds}`}
                            location={location}
                            displayWidth={displayWidth}
                            nextLocation={
                              index < routePlan.locations.length
                                ? routePlan.locations[index + 1]
                                : {}
                            }
                            style={{
                              opacity: isVisibleLocation(
                                loadingRatios,
                                location
                              )
                                ? 1.0
                                : 0.2,
                            }}
                            onClick={() =>
                              this.onClickLocation(routePlan, location)
                            }
                          />
                        )
                      )}
                  </div>
                </div>
              ))}

              {/* 現在時刻の青線 */}
              <CurrentTime
                height={
                  ((isShowDetail
                    ? LOCATION_CARD_HEIGHT
                    : SIMPLE_LOCATION_CARD_HEIGHT) +
                    16) *
                    routePlans.length -
                  12
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  core: { user, window, visibleMenu },
  routeList: {
    search,
    loadingRatios,
    displayWidth,
    routeType,
    isShowDetail,
    routePlans,
    locationMasters,
  },
}: any) => ({
  search,
  loadingRatios,
  displayWidth,
  routeType,
  isShowDetail,
  routePlans,
  locationMasters,
  user,
  window,
  visibleMenu,
});

const mapDispatchToProps = {
  changeSearch,
  clearRouteMasters,
  updateRouteMaster,
  removeRouteMaster,
  changeLoadingRatio,
  openModal,
  closeModal,
  changeDisplayWidth,
  changeRouteType,
  changeShowDetail,
  updateLocationMasters,
  openProgress,
  closeProgress,
  changeWindowSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteListPage);

const calcScrollWidth = (displayWidth: number) => {
  return (
    WIDTH_OF_1HOUR * (FAR_RIGHT_TIME - FAR_LEFT_TIME) * (60 / displayWidth)
  );
};

const convertRoutePlan = async (
  routePlanId: string,
  data: any,
  updateRouteMaster: (payload: any) => void
) => {
  // シリアライズできないデータを除外する
  const docData = _.omit(data, [
    'locations',
    'route_master_ref',
    'period_to',
    'period_from',
    'destination_time',
    'departure_time',
    'shipper_ref',
  ]);

  const departure: any = _.first(data.locations);
  const destination: any = _.last(data.locations);

  const extendedDocData: any = {
    ...docData,
    id: routePlanId,
    departure_time_seconds: departure.departure_time.seconds,
    destination_time_seconds: destination.destination_time.seconds,
  };

  const routeMasterRef = await data.route_master_ref.get();
  const routeMasterRefData = routeMasterRef.data();
  // シリアライズできないデータを除外する
  const omittedRouteMasterRefData = _.omit(routeMasterRefData, [
    'period_to',
    'period_from',
    'destination_time',
    'departure_time',
    'locations',
    'shipper_ref',
  ]);
  const routeMaster = {
    ...omittedRouteMasterRefData,
    period_from_seconds: routeMasterRefData.period_from.seconds,
    period_to_seconds: routeMasterRefData.period_to.seconds,
  };

  const locationRefs = data.locations.map((l: any) => l.location_ref);
  const promises = locationRefs.map((locationRef: any) => locationRef.get());
  const locationMasters: any[] = await Promise.all(promises);

  const locations = data.locations.map((location: any, index: number) => {
    const l = _.omit(location, [
      'location_ref',
      'destination_time',
      'departure_time',
      'destination_time_achievement',
      'departure_time_achievement',
    ]);

    const locationMaster: any = locationMasters[index];

    return {
      ...l,
      id: locationMasters[index].id,
      location: locationMaster.data(),
      destination_time_seconds: location.destination_time.seconds,
      departure_time_seconds: location.departure_time.seconds,
    };
  });

  extendedDocData.route_master_ref = routeMaster;
  extendedDocData.locations = _.sortBy(locations, (location: any) => {
    return location.destination_time_seconds;
  });

  updateRouteMaster({
    routeMasterId: routePlanId,
    data: extendedDocData,
  });
};

const isVisibleLocation = (
  loadingRatios: Array<number> | null,
  location: RouteMasterLocationFields
) => {
  const capacity =
    location.achievement_flg === ACHIEVEMENT_NOT_ENTERED
      ? location.departure_capacity_plan
      : location.capacity;

  if (!loadingRatios) {
    return true;
  }
  // 複数の条件のどれかに合致する場合はtrueを返す
  return loadingRatios.some((loadingRatio) => {
    // 選択した絞り込み条件(loadingRatio)と出発時積載率(capacity)を比較し、条件に合致する場合trueを返す

    // 0%＝0%
    if (loadingRatio === 0 && capacity === 0) {
      return true;
    }

    /*
     * 25%＝0%より大、かつ25%以下（0 < capacity <=25)
     * 50%＝25%より大、かつ50%以下（25 < capacity <=50)
     * 75%＝50%より大、かつ75%以下（50 < capacity <=75）
     * 100%＝75%より大（75 < capacity)
     */
    if (loadingRatio - 25 < capacity && capacity <= loadingRatio) {
      return true;
    }
    return false;
  });
};
