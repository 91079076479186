import React, { VFC } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelector } from '../store';
import { closeModal } from '../reducers/core';
import { useRouteMasterForm } from '../hooks/useRouteMasterForm';
import {
  DataModalActions,
  DataModalContent,
  DataModalError,
  DataModalHeader,
  DataModalListHeading,
  DataModalLayout,
  DataModalList,
  DataModalListItem,
  DataModalLoading,
  DataModalTitle,
} from '../components/DataModal';

import { Label } from '../components/shared/Label';

export const RouteMasterDetailContainer: VFC = () => {
  const dispatch = useDispatch();
  const { content } = useSelector(modalSelector);
  const { id } = (content as unknown) as { id: string };

  const results = useRouteMasterForm(id);

  const close = () => {
    dispatch({
      type: closeModal.type,
      payload: {
        type: 'routeMasterDetail',
      },
    });
  };

  return (
    <DataModalLayout>
      <DataModalHeader>
        <DataModalActions
          onCloseClick={close}
          disabled={results.loading || (!results.loading && !!results.error)}
        />
      </DataModalHeader>
      {results.loading && <DataModalLoading message={'情報の取得'} />}
      {!results.loading && results.data && (
        <>
          <DataModalContent>
            <DataModalTitle
              value={results.data.name}
              label={<Label size={'small'} label={results.data.routeType} />}
            />
            <DataModalList divider>
              <DataModalListItem
                label={'運行便コード'}
                value={results.data.routeCode}
                fullWidth
              />
            </DataModalList>
            <DataModalList divider>
              <DataModalListItem
                label={'出発地'}
                value={results.data.departureLocation.name}
                fullWidth
                bold
              />
              <DataModalListItem
                label={'住所'}
                value={results.data.departureLocation.area}
                fullWidth
              />
              <DataModalListItem
                label={'出発計画時刻'}
                value={moment(
                  results.data.departureLocation.departureTime
                ).format('hh:mm')}
                fullWidth
              />
              <DataModalListItem
                label={
                  <>
                    積み計画
                    <br />
                    （積載率）
                  </>
                }
                value={results.data.departureLocation.departureCapacityPlan}
              />
              <DataModalListItem
                label={'種別'}
                value={results.data.departureLocation.locationType}
              />
            </DataModalList>
            {results.data.locations.map((location, idx) => (
              <DataModalList divider key={location.id}>
                <DataModalListItem
                  label={`立寄地${idx + 1}`}
                  value={location.name}
                  fullWidth
                  bold
                />
                <DataModalListItem
                  label={'住所'}
                  value={location.area}
                  fullWidth
                />
                <DataModalListItem
                  label={'到着計画時刻'}
                  value={moment(location.destinationTime).format('hh:mm')}
                />
                <DataModalListItem
                  label={'出発計画時刻'}
                  value={moment(location.departureTime).format('hh:mm')}
                />
                <DataModalListItem
                  label={'荷降ろし計画（積載率）'}
                  value={location.departureUnloadingPlan}
                />
                <DataModalListItem
                  label={
                    <>
                      積み計画
                      <br />
                      （積載率）
                    </>
                  }
                  value={location.departureCapacityPlan}
                />
                <DataModalListItem
                  label={'種別'}
                  value={location.locationType}
                />
              </DataModalList>
            ))}
            <DataModalList divider>
              <DataModalListItem
                label={'到着地'}
                value={results.data.destinationLocation.name}
                fullWidth
                bold
              />
              <DataModalListItem
                label={'住所'}
                value={results.data.destinationLocation.area}
                fullWidth
              />
              <DataModalListItem
                label={'到着計画時刻'}
                value={moment(
                  results.data.destinationLocation.destinationTime
                ).format('hh:mm')}
                fullWidth
              />
              <DataModalListItem
                label={'荷降ろし計画（積載率）'}
                value={results.data.destinationLocation.departureUnloadingPlan}
              />
              <DataModalListItem
                label={'種別'}
                value={results.data.destinationLocation.locationType}
              />
            </DataModalList>
            <DataModalList>
              <DataModalListHeading title={'運行車両情報'} />
              <DataModalListItem
                label={'運行有効期限'}
                value={`${moment(results.data.periodFrom).format(
                  'YYYY/MM/DD'
                )} 〜 ${moment(results.data.periodTo).format('YYYY/MM/DD')}`}
              />
              <DataModalListItem
                label={'共有範囲'}
                value={results.data.shareCategory}
              />
              <DataModalListItem
                label={'車両区分'}
                value={results.data.vehicleOwner}
              />
              <DataModalListItem
                label={'契約方法'}
                value={results.data.contractType}
              />
              <DataModalListItem
                label={'協力会社名'}
                value={results.data.partnerName}
                fullWidth
              />
              <DataModalListItem
                label={'荷主名'}
                value={results.data.shipperName}
              />
              <DataModalListItem
                label={'車種'}
                value={`${results.data.vehicleClass} [t]`}
              />
              <DataModalListItem
                label={'形状'}
                value={results.data.shapeType.join(' ')}
              />
              <DataModalListItem
                label={'料金'}
                value={`¥${Number(results.data.fee).toLocaleString()}`}
              />
              <DataModalListItem
                label={'備考'}
                value={results.data.notes}
                fullWidth
              />
            </DataModalList>
          </DataModalContent>
        </>
      )}
      {!results.loading && results.error && <DataModalError message={'取得'} />}
    </DataModalLayout>
  );
};
