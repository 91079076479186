export enum LoginStatus {
  NOT_LOGIN,
  IN_PROGRESS,
  FAILED,
  SUCCEEDED,
}

export interface User {
  uid: string;
  email: string;
  displayName: string;
  loginStatus: LoginStatus;
}

// HH:mm 形式の5文字の時刻
export type TimeString = string;

export type UnixTimestamp = number;
