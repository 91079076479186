import moment from 'moment';
import { useEffect, useState } from 'react';

import { FAR_LEFT_TIME, WIDTH_OF_1HOUR } from '../../../constants/RoutList';
import { getHoursBy30HourClock } from '../../../services/Date';

interface Props {
  height: number;
}

// 現在時刻の青線
const CurrentTime = ({ height }: Props) => {
  const [time, changeTime] = useState(new Date());

  useEffect(() => {
    const timerId = setTimeout(() => {
      changeTime(new Date());
    }, 60 * 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [time]);

  const hours = getHoursBy30HourClock(moment(time).unix());

  const minutes = hours * 60 + time.getMinutes();
  const left =
    (minutes / 60) * WIDTH_OF_1HOUR -
    WIDTH_OF_1HOUR * FAR_LEFT_TIME +
    WIDTH_OF_1HOUR / 2;

  return (
    <div
      className="position-absolute"
      style={{
        top: 0,
        left,
        height,
      }}
    >
      <div
        style={{
          borderRadius: '50%',
          backgroundColor: '#247CDB',
          padding: 6,
        }}
      />
      <div
        style={{
          width: 2,
          height: '100%',
          marginLeft: 5,
          borderLeft: '2px solid #247CDB',
        }}
      />
    </div>
  );
};

export default CurrentTime;
