import { toTimeFormat } from '../../formatter/DateTimeFormatter';
import Achievement from '../../pages/RouteList/components/Achievement';

interface Props {
  content: any;
  onClickClose: () => void;
}

const LocationModal = ({ content, onClickClose }: Props) => {
  const {
    routePlan: { route_master_ref },
  } = content;
  const {
    // base
    destination_time_seconds,
    departure_time_seconds,
    loading_plan,
    unloading_plan,
    loading_achievement,
    unloading_achievement,
    items,
    // objects
    location,
  } = content.location;
  const { name, address, location_code,  abbreviation} = location;
  const loadingAchievement = items?.loading || {};
  const unloadingAchievement = items?.unloading || {};
  return (
    <div>
      <h3>{name} 【{abbreviation}】(拠点コード: {location_code})</h3>

      <hr />

      <div className="d-flex">
        <div className="fs-12 color-gray60 mr-3" style={{ width: 96 }}>
          拠点名
        </div>
        <div>
          <div className="fs-14">{name} 【{abbreviation}】</div>
          <div className="fs-14">{address}</div>
        </div>
      </div>

      <hr />

      <div className="d-flex align-items-center">
        <div className="fs-12 color-gray60 mr-3" style={{ width: 96 }}>
          便名
        </div>
        <div>
          <div className="fs-14">{route_master_ref.name}</div>
        </div>
      </div>

      <hr />

      <div className="d-flex align-items-center">
        <div className="fs-12 color-gray60 mr-3" style={{ width: 96 }}>
          発着予定
        </div>
        <div className="fs-14">
          <span>{toTimeFormat(destination_time_seconds)}着</span>
          <i className="fas fa-arrow-right mx-2" />
          <span>{toTimeFormat(departure_time_seconds)}発</span>
          <span>
            （作業時間
            {(departure_time_seconds - destination_time_seconds) / 60}分）
          </span>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-between align-items-center">
        {/* left */}
        <div className="w-50 d-flex">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 96 }}>
            到着時刻
          </div>
          <div className="fs-14">
            <span>{toTimeFormat(destination_time_seconds)}</span>

            {/* TODO まだ実績登録できなくてよい */}
            {/* <TimeSelect name="destination_time" /> */}
          </div>
        </div>
        {/* right */}
        <div className="w-50 d-flex">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 96 }}>
            出発時刻
          </div>
          <div>
            <div className="fs-14">
              <span>{toTimeFormat(departure_time_seconds)}</span>

              {/* TODO まだ実績登録できなくてよい */}
              {/* <TimeSelect name="departure_time" /> */}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-between">
        {/* left */}
        <div className="w-50 d-flex align-items-center">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 280 }}>
            荷降ろし計画値【積載率】
          </div>
          <div className="fs-14">{unloading_plan}</div>
        </div>
        {/* right */}
        <div className="w-50 d-flex align-items-center">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 280 }}>
            積み計画値【積載率】
          </div>
          <div className="fs-14">{loading_plan}</div>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-between mb-3">
        {/* left */}
        <div className="w-50 d-flex align-items-center">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 280 }}>
            荷降ろし実績値【積載率】
          </div>
          <div className="fs-14">{unloading_achievement}</div>
        </div>
        {/* right */}
        <div className="w-50 d-flex align-items-center">
          <div className="fs-12 color-gray60 mr-3" style={{ width: 280 }}>
            積み実績値【積載率】
          </div>
          <div className="fs-14">{loading_achievement}</div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {/* left */}
        <div className="d-flex w-50">
          <Achievement values={unloadingAchievement} className="ml-3" />

          {/* TODO まだ実績登録できなくてよい */}
          {/* <button className="btn btn-secondary mr-2">-</button>
          <input type="number" className="mr-2" style={{ width: 40 }} />
          <button className="btn btn-secondary">+</button> */}
        </div>

        {/* right */}
        <div className="d-flex w-50">
          <Achievement values={loadingAchievement} className="ml-3" />

          {/* TODO まだ実績登録できなくてよい */}
          {/* <button className="btn btn-secondary mr-2">-</button>
          <input type="number" className="mr-2" style={{ width: 40 }} />
          <button className="btn btn-secondary">+</button> */}
        </div>
      </div>

      <div className="text-center mt-3">
        <button className="btn btn-primary" onClick={onClickClose}>
          閉じる
        </button>
      </div>
    </div>
  );
};

export default LocationModal;
