import firebase from 'firebase';

import { ACHIEVEMENT } from '../../constants/BaseView';
import { VEHICLE_CLASSES } from '../../constants/RoutList';
import { BaseViewLocation } from '../../types/BaseView/types';

export const baseViewLocationsConverter = {
  toFirestore: () => {
    return {};
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): BaseViewLocation[] => {
    const data = snapshot.data(options);

    const vehicleClass = VEHICLE_CLASSES[data.vehicle_class];

    return data.locations.map(
      (location: any) =>
        ({
          name: data.name, // 便名
          shipperName: data.shipper_name, // 荷主（検索用）
          partnerName: data.partner_name, // 協力会社
          locationsName: location.location_name, // 拠点名
          destinationTime: location.destination_time.seconds, // 到着時間
          isAchieved: location.achievement_flg === ACHIEVEMENT.ACHIEVED, // 未入力表示
          vehicleClass, // 車種
          departureCapacityPlan: location.departure_capacity_plan, // 出発時計画
          departureCapacity: location.capacity, // 出発時実績
          destinationCapacityPlan: location.destination_capacity_plan, // 到着時計画
          destinationCapacity: location.destination_capacity, // 到着時実績
        } as BaseViewLocation)
    );
  },
};
