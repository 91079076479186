import React, { VFC } from 'react';
import { AchievementDownload } from '../AchievementDownload';

interface Props {
  onClickClose: () => void;
}

export const AchievementDownloadModal: VFC<Props> = ({
  onClickClose,
}: Props) => {
  return <AchievementDownload onClickClose={onClickClose} />;
};
