import React from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';
import styled from 'styled-components';

const StyledTableRow = styled(TableRow)`
  white-space: nowrap;
`;

export const RouteMasterTableHeader = () => (
  <TableHead>
    <StyledTableRow>
      <TableCell align={'left'} width={280}>
        運行コード
      </TableCell>
      <TableCell align={'left'} width={120}>
        運行便名
      </TableCell>
      <TableCell align={'left'} width={200}>
        出発地
      </TableCell>
      <TableCell align={'left'} width={200}>
        到着地
      </TableCell>
      <TableCell align={'left'}>車両区分</TableCell>
      <TableCell align={'left'}>協力会社名</TableCell>
      <TableCell align={'left'}>車種</TableCell>
      <TableCell align={'left'}>形状</TableCell>
    </StyledTableRow>
  </TableHead>
);
