import React, { VFC } from 'react';
import { LocaleUtils } from 'react-day-picker';
import { DAY_COLORS } from '../constants/shared';

type Props = {
  weekday: number;
  className: string;
  localeUtils: LocaleUtils;
  locale: string;
};

/**
 * @see https://react-day-picker.js.org/examples/elements-navbar/
 */
export const DateRangePickerWeekDay: VFC<Props> = ({
  localeUtils,
  weekday,
  locale,
  className,
}) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  const color =
    weekday === 0
      ? DAY_COLORS.SUNDAY
      : weekday === 6
      ? DAY_COLORS.SATURDAY
      : DAY_COLORS.DEFAULT;
  return (
    <div className={className} title={weekdayName} style={{ color: color }}>
      {weekdayName.slice(0, 1)}
    </div>
  );
};
