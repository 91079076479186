import React, { FC } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';

type Props = { divider?: boolean };

export const DataModalList: FC<Props> = ({ children, divider }) => (
  <Box pb={2}>
    <Grid container spacing={2}>
      {children}
      {divider && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  </Box>
);
