import 'firebase/auth';
import 'firebase/firestore';

import fb from 'firebase/app';

import {
  RouteMaster,
  RouteMasterDetail,
  RouteMasterDetailLocation,
} from '../types/RouteMasterList/types';
import { FIRESTORE_COLLECTION_KEY } from '../constants/Firebase';
import {
  LocationFields,
  RouteMasterFields,
  ShipperFields,
} from '../types/firestore';
import {
  CONTRACT_TYPES,
  LOCATION_TYPES,
  ROUTE_TYPES,
  SHAPE_TYPES,
  SHARE_CATEGORIES,
  VEHICLE_CLASSES,
  VEHICLE_OWNERS,
} from '../constants/shared';

export const RouteMasterService = {
  findAll: async (): Promise<RouteMaster[]> => {
    try {
      const user = fb.auth().currentUser;
      if (!user) {
        throw new Error('Unauthenticated Error');
      }
      const clientSnap = await fb
        .firestore()
        .collection(FIRESTORE_COLLECTION_KEY.CLIENT)
        .where('userIds', 'array-contains', user.uid)
        .get();

      const [doc] = clientSnap.docs;
      const routeMasterSnap = await doc.ref
        .collection(FIRESTORE_COLLECTION_KEY.ROUTE_MASTER)
        .orderBy('route_code')
        .where('delete_flg', '==', 0)
        .get();

      return routeMasterSnap.docs
        .map((snap) => {
          return { ...snap.data(), id: snap.id } as RouteMasterFields & {
            id: string;
          };
        })
        .map<RouteMaster>((r) => {
          const departureLocation = r.locations[0];
          const destinationLocation = r.locations[r.locations.length - 1];
          const shapeType = r.shape_type.map((t) => SHAPE_TYPES[t]);
          const vehicleClass = VEHICLE_CLASSES[r.vehicle_class];
          const vehicleOwner = VEHICLE_OWNERS[r.vehicle_owner];

          return {
            id: r.id,
            routeCode: r.route_code,
            name: r.name,
            partnerName: r.partner_name,
            departureLocationName: departureLocation.location_name,
            destinationLocationName: destinationLocation.location_name,
            vehicleClass,
            vehicleOwner,
            shapeType,
          };
        });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  findById: async (id: string): Promise<RouteMasterDetail> => {
    try {
      const user = fb.auth().currentUser;
      if (!user) {
        throw new Error('Unauthenticated Error');
      }

      const clientSnap = await fb
        .firestore()
        .collection(FIRESTORE_COLLECTION_KEY.CLIENT)
        .where('userIds', 'array-contains', user.uid)
        .get();
      const [doc] = clientSnap.docs;

      const routeMasterDocRef = await doc.ref
        .collection(FIRESTORE_COLLECTION_KEY.ROUTE_MASTER)
        .doc(id);

      const snap = await routeMasterDocRef.get();
      const routeMaster = {
        ...snap.data(),
        id: snap.id,
      } as RouteMasterFields & {
        id: string;
      };

      const locations = await Promise.all<RouteMasterDetailLocation>(
        routeMaster.locations.map(async (location) => {
          const ref = await location.location_ref.get();
          const doc = ref.data() as LocationFields;

          return {
            id: ref.id,
            locationType: LOCATION_TYPES[location.location_type],
            name: doc.name,
            area: doc?.address,
            departureTime: location.departure_time.toDate(),
            destinationTime: location.destination_time.toDate(),
            departureCapacityPlan: location.capacity,
            departureUnloadingPlan: location.unloading_plan,
          };
        })
      );

      const shipperName = routeMaster.shipper_ref
        ? await routeMaster.shipper_ref
            .get()
            .then((doc) => (doc.data() as ShipperFields).name)
        : '';

      const departureLocation = locations[0];
      const destinationLocation = locations[routeMaster.locations.length - 1];
      const routeType = ROUTE_TYPES[routeMaster.type];
      const shapeType = routeMaster.shape_type.map((t) => SHAPE_TYPES[t]);
      const vehicleClass = VEHICLE_CLASSES[routeMaster.vehicle_class];
      const vehicleOwner = VEHICLE_OWNERS[routeMaster.vehicle_owner];
      const contractType = CONTRACT_TYPES[routeMaster.contract_type];
      const shareCategory = SHARE_CATEGORIES[routeMaster.share_scope];

      return {
        id: routeMaster.id,
        routeCode: routeMaster.route_code,
        routeType,
        name: routeMaster.name,
        departureLocation,
        destinationLocation,
        locations: locations.slice(1, locations.length - 1),
        vehicleClass,
        vehicleOwner,
        shapeType,
        contractType,
        shareCategory,
        notes: routeMaster.notes,
        partnerName: routeMaster.partner_name,
        fee: routeMaster.fee,
        periodFrom: routeMaster.period_from.toDate(),
        periodTo: routeMaster.period_to.toDate(),
        shipperName,
      };
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
};
