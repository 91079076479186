import { createAction, createReducer } from '@reduxjs/toolkit'
import { LoginStatus } from '../pages/RouteList/types'

export const openModal = createAction('CORE/OPEN_MODAL')
export const closeModal = createAction('CORE/CLOSE_MODAL')
export const openMenu = createAction('CORE/OPEN_MENU')
export const closeMenu = createAction('CORE/CLOSE_MENU')
export const updateUser = createAction('CORE/CHENGE_USER')
export const openProgress = createAction('CORE/OPEN_PROGRESS')
export const closeProgress = createAction('CORE/CLOSE_PROGRESS')
export const changeWindowSize = createAction('CORE/CHANGE_WINDOW_SIZE')

const { innerWidth, innerHeight } = global.window

const initialState = {
  user: {
    uid: '',
    email: '',
    displayName: '',
    loginStatus: LoginStatus.NOT_LOGIN,
  },
  modal: {
    visible: false,
    type: '',
    content: {},
  },
  visibleMenu: false,
  visibleProgress: false,
  window: {
    innerWidth,
    innerHeight,
  },
}

export default createReducer(initialState, {
  // modal
  [openModal.type]: (state, action) => {
    const { type, content } = action.payload
    return {
      ...state,
      modal: {
        ...state.modal,
        visible: true,
        type,
        content,
      },
    }
  },
  [closeModal.type]: (state) => {
    return {
      ...state,
      modal: {
        ...state.modal,
        visible: false,
        type: '',
        content: {},
      },
    }
  },
  // menu
  [openMenu.type]: (state) => {
    return {
      ...state,
      visibleMenu: true,
    }
  },
  [closeMenu.type]: (state) => {
    return {
      ...state,
      visibleMenu: false,
    }
  },

  // user
  [updateUser.type]: (state, action) => {
    const user = action.payload
    return {
      ...state,
      user,
    }
  },

  // progress
  [openProgress.type]: (state) => {
    return {
      ...state,
      visibleProgress: true,
    }
  },
  [closeProgress.type]: (state) => {
    return {
      ...state,
      visibleProgress: false,
    }
  },

  [changeWindowSize.type]: (state, action) => {
    const { innerWidth, innerHeight } = action.payload
    return {
      ...state,
      window: {
        innerWidth,
        innerHeight,
      },
    }
  },
})
