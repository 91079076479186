/** UIで利用する曜日の色 */
export const DAY_COLORS = {
  DEFAULT: '#808080',
  SATURDAY: '#0046C2',
  SUNDAY: '#DB2464',
};

/** 車種 */
export const VEHICLE_CLASSES = [
  '2t',
  '2t-L',
  '4t',
  '4tワイド',
  '10t（14）',
  '10t（16）',
  '10t（18）',
  '13t',
  '15t',
  '19t',
  '20t',
  '25t',
] as const;

/** 車両区分 */
export const VEHICLE_OWNERS = ['自社', '協力会社'] as const;

/** 形状 */
export const SHAPE_TYPES = [
  'バン',
  'ウイング',
  '平ボディ',
  'トレーラー',
  '低床',
  '中低床',
  'フルワイド',
  'セミワイド',
  'エアサス',
  'パワーＧ',
  '冷凍',
  '冷蔵',
  'ユニック',
] as const;

/** 拠点種別 */
export const LOCATION_TYPES = [
  '自社物流拠点',
  '自社営業拠点',
  '他社物流拠点',
  '納品先',
] as const;

/** 共有範囲 */
export const SHARE_CATEGORIES = [
  '全て',
  'カテゴリ1',
  'カテゴリ2',
  'カテゴリ3',
] as const;

/** 契約形態 */
export const CONTRACT_TYPES = ['個建契約', '車建契約'] as const;

/** 運行タイプ */
export const ROUTE_TYPES = ['拠点間', '幹線', '立寄便'] as const;

/** 地域 */
export const LOCATION_AREAS = [
  '北海道',
  '東北',
  '関東',
  '中部',
  '近畿',
  '中国',
  '四国',
  '九州沖縄',
] as const;
