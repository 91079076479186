interface Props {
  width: number;
  locationWidth: number;
  color: string;
  style: any;
}

// 簡易表示用の実績折れ線
const AchievementLine = ({ width, locationWidth, color, style }: Props) => (
  <div
    style={{
      marginLeft: locationWidth,
      width: width - locationWidth,
      height: 2,
      backgroundColor: color,
      ...style,
    }}
  ></div>
);

export default AchievementLine;
