import firebase from 'firebase';
import { Location } from '../../types/BaseView/types';

export const locationsConverter = {
  toFirestore: () => {
    return {};
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Location => {
    const data = snapshot.data(options);
    return {
      name: data.name,
    } as Location;
  },
};
