import React, { VFC } from 'react';
import styled from 'styled-components';

import { Table, TableBody, TableRow } from '@material-ui/core';

import { RouteMaster } from '../../types/RouteMasterList/types';
import { RouteMasterTableShapeTypeCell } from './RouteMasterTableShapeTypeCell';
import { RouteMasterTableVehicleClassCell } from './RouteMasterTableVehicleClassCell';
import { RouteMasterTableCell } from './RouteMasterTableCell';
import { RouteMasterTableHeader } from './RouteMasterTableHeader';

type Props = {
  routeMasters: RouteMaster[];
  clickRow: (route: RouteMaster) => void;
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const RouteMasterTable: VFC<Props> = ({ routeMasters, clickRow }) => (
  <Table>
    <RouteMasterTableHeader />
    <TableBody>
      {routeMasters.map((route) => (
        <StyledTableRow onClick={() => clickRow(route)} key={route.id} hover>
          <RouteMasterTableCell>{route.routeCode}</RouteMasterTableCell>
          <RouteMasterTableCell>{route.name}</RouteMasterTableCell>
          <RouteMasterTableCell>
            {route.departureLocationName}
          </RouteMasterTableCell>
          <RouteMasterTableCell>
            {route.destinationLocationName}
          </RouteMasterTableCell>
          <RouteMasterTableCell>{route.vehicleOwner}</RouteMasterTableCell>
          <RouteMasterTableCell>{route.partnerName}</RouteMasterTableCell>
          <RouteMasterTableVehicleClassCell vehicleClass={route.vehicleClass} />
          <RouteMasterTableShapeTypeCell shapeTypes={route.shapeType} />
        </StyledTableRow>
      ))}
    </TableBody>
  </Table>
);
