import React, { VFC } from 'react';
import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';
import { Close, DeleteOutline, Edit } from '@material-ui/icons';

const iconColor = 'gray';

const StyledButton = styled(Button)({
  cursor: 'not-allowed',
  '&:focus': {
    outline: 'none',
  },
});

const StyledDeleteIcon = styled(DeleteOutline)`
  color: ${iconColor};
`;

const StyledCloseIcon = styled(Close)`
  color: ${iconColor};
`;

const StyledEditIcon = styled(Edit)`
  color: ${iconColor};
`;

type Props = {
  onCloseClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  disabled: boolean;
};

export const DataModalActions: VFC<Props> = ({
  onCloseClick,
  disabled,
}: Props) => (
  <Box display={'flex'} justifyContent={'flex-end'} pb={2}>
    <Box>
      <StyledButton startIcon={<StyledDeleteIcon />} disabled={disabled}>
        削除
      </StyledButton>
    </Box>
    <Box ml={2}>
      <StyledButton startIcon={<StyledEditIcon />} disabled={disabled}>
        編集
      </StyledButton>
    </Box>
    <Box ml={2}>
      <StyledButton
        onClick={onCloseClick}
        startIcon={<StyledCloseIcon />}
        disabled={disabled}
      >
        閉じる
      </StyledButton>
    </Box>
  </Box>
);
