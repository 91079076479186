import React, { FC } from 'react';
type Props = {
  value: string | number;
  label: string;
  className: string;
  selected: boolean;
  onClick: (value: string | number) => void;
};
export const FilterButton: FC<Props> = ({
  value,
  label,
  className,
  selected = false,
  onClick,
}: Props) => (
  <button
    type="button"
    className={className}
    style={{
      color: selected ? '#247CDB' : '#666666',
      backgroundColor: selected ? '#D6EBFF' : 'white',
      border: `1px solid ${selected ? '#247CDB' : '#E0E0E0'}`,
      borderRadius: 50,
      outline: 'none',
      width: 52,
      height: 36,
      fontSize: 12,
    }}
    onClick={() => onClick(value)}
  >
    {label}
  </button>
);
